import React, { Component } from "react";
import CommonUtils from "../../utils/common";

export default class StorePolicy extends Component {
    render () {
        return <div id={'store-policy'} className={'p-md-4 p-1'}>
            <h5 className={CommonUtils.pageHeaderClassName}>Meditation Room & Store Privacy Policy</h5>
            <h6 className={'text-uppercase color-maroon'}>Shipment</h6>
            <p>
                Shipping and handling charges are extra, and are based on the weight, size and
                method of shipment. Larger and/or heavier orders are sent via UPS unless otherwise
                requested. Smaller packages are usually sent via priority mail. Back orders will be
                shipped at the same rates as regular orders. International orders require additional
                shipping fees and are usually sent via air mail. It may not be possible to insure some
                international orders. Customer is responsible for all customs duties, VAT charges,
                and similar fees, where applicable. There is an extra charge for COD orders and
                special handling. Please contact us with special requests and for more information.
            </p>
            <h6 className={'text-uppercase color-maroon'}>Retail refund policy</h6>
            <p>
                Returns will only be accepted and credit given or money refunded if ALL of the
                following conditions are met:
            </p>
            <ul>
                <li>
                    Items must be in original condition as shipped.
                </li>
                <li>
                    Notification of your intention to make a return must be received by us within ten
                    days of delivery of the item(s).
                </li>
                <li>
                    Returned item(s) must be received by us within fourteen days of the date the item
                    was delivered.
                </li>
                <li>
                    Customer is financially responsible for all shipping and handling charges
                    connected with the item, and assumes full responsibility for its safe return. We
                    suggest careful packing and insurance as the minimum precaution.
                </li>
            </ul>
            <p className={'text-primary'}>
                <i><b>Note:</b> The <b>REFUND POLICY</b> does not apply on 'in store' purchases.  For all 'in
                    store' purchases, there is <b>NO CASH REFUND</b>. We issue store credits and allow
                merchandise exchange of a same value within 14 days of the original purchase date,
                provided that the merchandise you are about to return is in its original condition.<br/>
                    Thank you.</i>
            </p>
            <h6 className={'text-uppercase color-maroon'}>Insurance and damages</h6>
            <p>
                Almost all orders are insured. If you receive an item that has been damaged during
                shipment, please contact us immediately. Save ALL packing materials and
                paperwork. If you notice external damage to the package at the time of delivery,
                alert the delivery person at that time, and, if possible, have them verify this damage
                in writing.
            </p>
            <h6 className={'text-uppercase color-maroon'}>Privacy Policy (updated June 9,2022)</h6>
            <p>
                The Land of Buddha operates this website, providing products and services (“Services”) through the
                website and mobile applications. We value your trust and respect your privacy. The purpose of this
                notice (“Privacy Policy”) is to be transparent about how information is collected and used, and to assist
                you in understanding our Privacy Policy and your rights. By using our Platform, you consent to this
                Privacy Policy.
            </p>
            <h6 className={'text-uppercase color-maroon'}>A. What information does Land of Buddha collect?</h6>
            <p>
                When you register online (for event or services, or email sign up) on the Platform, we request the
                information we need to complete your order or request. This information may include your full name,
                billing address, shipping address, telephone number, email address, and if applicable, credit card
                number
            </p>
            <p>
                The information you provide will be used to process your order or request and improve your online
                experience. Once you have placed your order with us, we will verify with your credit card company that
                your credit card is valid and send you an email message that confirms your order was successfully
                received. We will also send you an email order confirmation once your registration has been finalized.
            </p>
            <h6 className={'text-uppercase color-maroon'}>B. Information from Other Sources</h6>
            <p>
                Land of Buddha may sometimes employ a third party to perform services. We may purchase data from
                agencies to acquire missing details, as well as to better identify or model our customers and inquiries, to
                help us shape our messages to your interests. These parties have agreed to hold this information in
                confidence and do not use it for any purpose except to carry out the requested service(s).
            </p>
            <h6 className={'text-uppercase color-maroon'}>C. What do we use your information for?</h6>
            <p>
                Any of the information Land of Buddha collects from you may be used in one or more of the following
                ways:
            </p>
            <ul>
                <li>To personalize your experience</li>
                <li>To improve our Platform</li>
                <li>To improve customer service</li>
                <li>To troubleshoot problems</li>
                <li>To send periodic emails</li>
                <li>To process your registrations</li>
            </ul>
            <h6 className={'text-uppercase color-maroon'}>D. Sharing with teachers and like-minded companies</h6>
            <p>
            Information about our participants and visitors is an important part of our business. Land of Buddha on
occasion may send you an email message inviting you to join the email list of the teachers who taught
the workshop or the practitioners who performed at the event you attended. If you choose to join a
teacher's email list, you are agreeing to the privacy policy of the teacher's email list and website. Land of
Buddha has no responsibility for any teacher’s email list or website.
            </p>
            <p>
            If you wish to opt out of having your contact information shared, please refer to the opt-out information
for Information Sharing and Mail List below.
            </p>
            <h6 className={'text-uppercase color-maroon'}>E. How to Opt Out</h6>
            <p>
            Land of Buddha believes in your privacy and has created easy steps for you to limit or control the way
Land of Buddha communicates with you and shares your information.
            </p>
            <h6 className={'text-uppercase color-maroon'}>E.1 Promotional Emails</h6>
            <p>
            If you do not wish to receive promotional emails from Land of Buddha such as our monthly newsletter
or weekly email promotions, please follow the instructions at the end of emails you receive from Land of
Buddha. From these emails you can unsubscribe or manage your subscription preferences. It can
sometimes take up to 5 business days for our systems to reflect your changes.
            </p>
            <h6 className={'text-uppercase color-maroon'}>E.2 Land of Buddha may contact you regarding customer service, workshops and class registrations,
order confirmations, and/or other related issues.</h6>
            <h6 className={'text-uppercase color-maroon'}>F. Cookies</h6>
            <p>
            Cookies are small files that a site or its service provider transfers to your computer’s hard drive through
your web browser (if you permit it) that enable the site or service provider’s system to recognize your
browser and capture and remember certain information. Land of Buddha uses cookies to help
remember and process the items in your shopping cart, understand and save your preferences for
future visits, and compile aggregate data about site traffic and site interaction so that Land of Buddha
can offer better site experiences and tools in the future.
            </p>
            <p>
            If you wish to clear tracking cookies from all of the websites you visit, you can change the preferences or
settings in your web browser to control cookies. The Help menu on the menu bar of most browsers will
tell you how. In some cases, you can choose to accept cookies from the primary site but block them
from third parties. In others, you can block cookies from specific advertisers or clear out all cookies.
            </p>
            <h6 className={'text-uppercase color-maroon'}>G. Browser and Device Information</h6>
            <p>
            Certain information is collected by most browsers or automatically through your device, such as your
Media Access Control (MAC) address, computer type (e.g., Windows or Mac), screen resolution,
operating system name and version, device manufacturer and model, language, Internet browser type
and version, and the name and version of the Services (such as the App) you are using. We use this
information to ensure that the Services function properly.
            </p>
            <h6 className={'text-uppercase color-maroon'}>H. Internet Protocol Address</h6>
            <p>
            Your IP address is automatically assigned to your computer by your Internet Service Provider. An IP
address may be identified and logged automatically in our server log files whenever a user accesses the
Services, along with the time of the visit and the page(s) that were visited. Collecting IP addresses is
standard practice and is done automatically by many websites, applications, and other services. Land of
Buddha may use IP addresses for purposes such as calculating usage levels, diagnosing server problems,
and administering the Services. Land of Buddha may also derive your approximate location from your IP
address.
            </p>
            <h6 className={'text-uppercase color-maroon'}>I. Web Server Traffic, Browsing History, Pixel Tags</h6>
            <p>
            Land of Buddha collects standard web server traffic pattern information and your browsing history.
General traffic, Platform usage, browser information, and length of stay information are collected and
stored in log files. Pixel tags (also known as web beacons and clear GIFs) may be used to, among other
things, track the actions of users of the Services (including email recipients), measure the success of our
marketing campaigns and compile statistics about usage of the Services and response rates.
            </p>
            <h6 className={'text-uppercase color-maroon'}>J. Targeted Outreach and Advertising</h6>
            <p>
            Land of Buddha may work with third-party service providers to target advertisements and outreach to
you based on your preferences or other information, in some cases combining data that is collected
from you through online cookies and other technologies with other de-identified data (such as a hashed,
non-readable email, or postal address). In addition, third-party service provider may be used to display
content-specific advertisements to you after you have left our Platforms when you go to other websites.
These third-party service providers may use cookies, web beacons, or similar technologies to tailor
advertisements to interests you’ve shown by browsing our Platforms or by visiting other websites. Land
of Buddha does not control the third-party service providers' tracking technologies or how they may be
used. The collection of your information or use of technologies by third-party service providers is subject
to those third-parties’ privacy policies, not Omega’s. For more information on these practices or to learn
how to opt-out of receiving targeted advertising from many service providers.
            </p>
            <p>
            Please visit <a href="http://optout.aboutads.info/#!/" rel="noopener noreferrer" target="_blank">http://optout.aboutads.info/#!/</a> and <a href="http://optout.networkadvertising.org/" rel="noopener noreferrer" target="_blank">http://optout.networkadvertising.org/</a>
            </p>
            <h6 className={'text-uppercase color-maroon'}>K. Analytics</h6>
            <p>
            Land of Buddha uses Google Analytics, which uses cookies and similar technologies to collect and
analyze information about your use of the Services and to report on activities and trends. This service
may also collect information regarding use of other websites, apps, and online resources. You can learn
about Google’s practices by going to www.google.com/policies/privacy/partners/ and opt-out of them
by downloading the Google Analytics opt-out browse
            </p>
            <h6 className={'text-uppercase color-maroon'}>L. Physical Location</h6>
            <p>
            Land of Buddha may collect the physical location of your device by, for example, using satellite, cell
phone tower, or Wi-Fi signals and may use your device’s physical location to provide you with
personalized location-based services and content. In some instances, you may be permitted to allow or
deny such uses and/or sharing of your device’s location, but if you do, you may not be provided with the
applicable personalized services and content from Land of Buddha.
            </p>
            <h6 className={'text-uppercase color-maroon'}>M. Links</h6>
            <p>
            Land of Buddha’s Platform contains links to other websites and does not provide any personally
identifiable customer information to these advertisers or third-party websites. Land of Buddha has no
control over the privacy practices of sites outside our domain. Please note that when you click on one of
these links, you are entering another site that is governed by that site’s privacy policy. Land of Buddha is
not responsible or liable in any way for, and make no representations or warranties about, the privacy,
security, data policies, practices, quality, accuracy, timeliness, or reliability of any third-party sites or
their content, advertisements, functionality, and links. Land of Buddha shall not be responsible or liable 
for any loss or damage of any sort incurred as the result of any dealings between you and any third
parties, or your access or use of any third-party site or content.
            </p>
            <h6 className={'text-uppercase color-maroon'}>N. Security</h6>
            <p>
            N.1 Land of Buddha implements a variety of security measures to maintain the safety of your personal
information when you place an order or access your personal information, including offering use of a
secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL)
technology and then encrypted into our payment gateway provider’s database only to be accessible by
those authorized with special access rights to such systems, and they are required to keep the
information confidential. However, as with all information submitted online, while we strive to protect
personal information, neither Land of Buddha nor our service providers can guarantee the security
(including but not limited to unauthorized access by third parties, loss, misuse, or alteration) of any
information you transmit to us over the Internet.
            </p>
            <p>
            N.2 After a transaction, your private financial information (credit card numbers or financial information,
etc.) will not be stored on our servers. Land of Buddha may retain such information as your name,
address, email, and information relevant to your order or reservation or form submission. 
            </p>
            <p>
            N.3 Incorrect or Fraudulent Information: A credit card transaction may be canceled by Land of Buddha if
we reasonably determine that the order information you provided is incorrect or fraudulent, or if we
reasonably believe the credit card utilized is from an unauthorized party.
            </p>
            <h6 className={'text-uppercase color-maroon'}>O. Land of Buddha’s Meditation Room & Wellness Services</h6>
            <p>
            Land of Buddha may ask for basic medical information that might impact or preclude you from receiving
certain Wellness Services, including information about injuries, surgeries, pregnancy, or medical
conditions such as cancer. This information is requested either by verbal confirmation or on written,
paper intake forms for guests who want to participate in a wellness. 
            </p>
            <h6 className={'text-uppercase color-maroon'}>P. Minors</h6>
            <p>
            Minors (anyone under the age of 18) are not eligible to pay for Land of Buddha’s services and we ask
that minors do not submit credit card information. Land of Buddha is not responsible for a minor's use
of their parent's credit card.
            </p>
            <p>
            P.1 The Children’s Online Privacy Protection Act (“COPPA”) requires that we inform parents and legal
guardians about how we collect, use, and disclose personal information (from children under 13 years of
age). The Platform is not directed at children under 13 years of age, but we recognize that with proper
adult supervision some parents might permit their children to visit and utilize the Plaform. COPPA
requires that we obtain the consent of parents and guardians for children under 13 years of age to use
certain features of the Platform.
            </p>
            <p>
            P.2 When your child attempts to register and/or provide personal information to Land of Buddha
(including on any of the Services), we require a parent or legal guardian to: complete the registration;
review our privacy policy and to submit a valid credit card number to verify that the child’s parent or
guardian knows about and authorizes our information practices related to children protected by COPPA;
and verify through the use of an email confirmation and second authorization that the parent or
guardian consents to any applicable Terms and Conditions and this Privacy Policy. 
            </p>
            <p>
            P.3 Once parental or guardian notice and consent has been verified, the information we collect will be
treated the same as information collected from any other user of the Services. Without verified
parental notice and consent, we will not knowingly collect personal information of children under 13
years of age, and if we learn that we have inadvertently collected such information, we will promptly
delete it, provided that we will not delete the information needed to (i) protect the security or integrity
of the Platform or Services; (ii) take precautions against liability; (iii) respond to judicial process; or (iv)
to the extent permitted under other provisions of law, to provide information to law enforcement
agencies or for an investigation on a matter related to public safety; and where such information is not
to be used for any other purpose.
            </p>
            <h6 className={'text-uppercase color-maroon'}>Q. Your Consent</h6>
            <p>
            By using the Land of Buddha’s Platform, you consent to the online Privacy Policy. Your information,
including personal data, may be transferred to—and maintained on—computers located outside of your
state, province, country, or other governmental jurisdiction where the data protection laws may differ
than those from your jurisdiction. If you are located outside the United States and choose to provide
information to us, please be aware that we transfer the data, including personal data, to the U.S. and
process it there. Your consent to this Privacy Policy, followed by your submission of information,
represents your agreement to that transfer.
            </p>
            <h6 className={'text-uppercase color-maroon'}>S. International Visitors to Our Platform</h6>
            <p>
            If you are located outside of the U.S., the personal information you provide to Land of Buddha will be
transferred to the U.S. and governed by applicable U.S. laws and regulations and this Privacy Policy.
Some of this information may be transferred to, processed, and stored temporarily by service providers
in other countries. By submitting your personal information, you consent to its transfer to and storage in
the U.S. and other designated countries, its exclusive governance by the law and regulations of the State
of New York and of the U.S., and its use in accordance with the purposes for which it was originally
collected and as set forth in this Privacy Policy.
            </p>
            <h6 className={'text-uppercase color-maroon'}>T. Processing operations</h6>
            <h6 className={'text-uppercase color-maroon'}>1. Hosting</h6>
            <p>
            To make our Platform available to the public, Land of Buddha use hosting services provided by hosting
providers, such as the supply of web servers, disk space, database services, security, and maintenance
services. Therefore, personal data of users is processed by our Platform based on our legitimate
interests under Art. 6 Para. 1 lit. f GDPR.
            </p>
            <h6 className={'text-uppercase color-maroon'}>2. Access Data and Log Files</h6>
            <p>
            When you access our Platform or one of its pages, the browser on your device automatically sends
information to the Platform’s server. This information is stored in so-called log files by us or our hosting
provider. The following information is stored:
            </p>
            <ul>
                <li>IP address of the computer requesting access to our Platform</li>
                <li>Date and time of the access</li>
                <li>Name and URL of the retrieved file</li>
                <li>Platform from which access is made (referrer URL)</li>
                <li>The browser used and, if applicable, the operating system of your computer</li>
                <li>Database changes and errors</li>
                <li>This data is processed for the following purposes, and the legal basis for such data processing is Art. 6</li>
                <li>Para. 1 p. 1 lit. of GDPR:</li>
                <li>Providing the Platform, including all functions and contents</li>
                <li>Enabling an unobstructed dial-up connection to the Internet</li>
                <li>Enabling easy utilization of our Platform</li>
                <li>Ensuring system security and stability</li>
                <li>Anonymized statistical evaluation of visitors accessing our Platform</li>
                <li>Platform optimization</li>
                <li>Other administrative purposes</li>
            </ul>
            <h6 className={'text-uppercase color-maroon'}>3. Registration / User Account</h6>
            <p>
            You can register on our Platform. Registering requires the providing of personal data. The registration is
voluntary and is in accordance with Art. 6 Para. 1 p. 1 lit. a GDPR based on your voluntary consent. The
personal data being transferred depends on the data input in the registration form. The personal data
recorded is used as described above and to contact you. You may access your personal data and make
changes to it. Your data will be stored until you delete the user account or instruct us to delete your
data. Other than obligations to retain your personal data based on statutory retention periods, under
tax and commercial law, the processing of your personal data will be restricted until the retention
period expires, and then the data will be deleted.
            </p>
            <p>
            If you register on our Platform or create a user account, we store the IP address and the time of the
respective use. This storage is based on our legitimate interest under Art. 6 Para. 1 p. 1 lit. f GDPR in
providing such options. The user account and data stored in connection with it also facilitate purchases,
access to historical orders, and the writing of customer reviews. Transfer of this data to third parties
does not take place unless it is required to fulfil contractual obligations according to Art. 6 Para. 1 lit. b
GDPR or for the prosecution of any claims or if there is a legal obligation according to Art. 6 Para. 1 lit. c
GDPR.
            </p>
            <h6 className={'text-uppercase color-maroon'}>4. Contract Data</h6>
            <p>
            In connection with and for the purpose of fulfilling pre-contractual measures and contractual obligations
via our Platform upon request of the data subject, we process the data of the data subject required for
the fulfillment of the contract. This includes:
            </p>
            <p>
            Data of the contractor, such as name, address and contact details, if applicable different delivery or
billing addresses or recipients, roommate request, and gender
            </p>
            <p>
            Contractual data, such as subject of the contract, duration, and customer category.
            </p>
            <p>
            Payment data, such as bank details, credit card data, and payment history.
            </p>
            <p>
            The legal basis for the data processing is Art. 6 Para. 1 p. 1 lit. b GDPR. The data will be transferred to
third parties only to the extent to fulfil pre-contractual and contractual obligation, e.g. to banks,
payment service providers, and credit card companies for the payment transaction and to email service
providers.
            </p>
            <h6 className={'text-uppercase color-maroon'}>5. Application Form</h6>
            <p>
            By using our application form you are asked to submit your name, contact information, and further
application information, so we can consider your application and contact you personally. Data to
process your application is processed according to Art. 6 Para. 1 sentence 1 lit. a GDPR on the basis of
your voluntarily given consent.
            </p>
            <h6 className={'text-uppercase color-maroon'}>U. Communication</h6>
            <h6 className={'text-uppercase color-maroon'}>1. Email Marketing to Customers</h6>
            <p>
            If you are our customer and we have received your email address in connection with the sale of a good
or service, we are authorized to use your email address for purposes of customer service, direct
marketing of similar goods or services. This only applies if you have not objected to such use and we
have explicitly informed you about the possibility to object to such use when collecting the email
address and every time we use your email address. Legal basis of such processing is our legitimate
interest in direct marketing according to Art. 6 Para. 1 lit. f GDPR.
            </p>
            <h6 className={'text-uppercase color-maroon'}>2. Newsletter</h6>
            <p>
            If you would like to receive our newsletter, we require your email address. The data processing for the
purpose of sending you our newsletter is carried out pursuant to Art. 6 Para. 1 p. 1 lit. a GDPR based on
your voluntary consent by means of the so-called double opt-in procedure. Your email address and
other provided details will be used and stored for this purpose until you revoke your consent or
unsubscribe from receiving the newsletter. It is possible to unsubscribe from our newsletter at any time,
for example via a link at the end of each newsletter. Alternatively, you can unsubscribe at any time by
sending an email to the email address documented under II.
            </p>
            <p>
            We send our newsletters with a so-called counting pixel. A counting pixel is a miniature graphic
embedded in the HTML format of the newsletter to allow for analysis of reader behavior. In this context,
we store whether and at what time a newsletter was opened by you and which of the links contained in
the newsletter you accessed. We use this data to generate statistical evaluations about the success or
failure of a marketing campaign in order to optimize the delivery of newsletters and to better tailor the
content of future newsletters to your interests. The collected data will not be passed on to third parties
and will be deleted after the statistical evaluation.
            </p>
            <h6 className={'text-uppercase color-maroon'}>V. Payment Service Provider</h6>
            <h6 className={'text-uppercase color-maroon'}>PayPal</h6>
            <p>
            This Platform uses PayPal as a payment service provider. Provider is PayPal (Europe) S.à r.l. et Cie, S.C.A.,
22-24 Boulevard Royal, L-2449 Luxembourg (hereafter “PayPal”). PayPal assumes the function of an
online payment provider as well as a trustee and provides buyer and seller protection services. In case of
payment via PayPal, credit card via PayPal, debit via PayPal, or – if offered – purchase on account via
PayPal, your name, email address, purchased products, invoice amount, and billing and shipping address
will be transferred to PayPal within the scope of the payment. If the payment methods credit card via
PayPal, debit via PayPal, or – if offered – purchase on account via PayPal are used, PayPal will, if 
applicable, request a credit rating query, to verify creditworthiness and minimize payment defaults
when deciding to accept the payment transaction. Probability values will be used for a credit rating
query (so-called score values), and address data will be included into the calculation. A recognized
mathematical-statistical method is the foundation of calculating those score values. If the credit rating is
insufficient, PayPal can reject the chosen payment method. Legal basis of such processing: Art. 6 Para. 1
lit. b GDPR.
            </p>
            <p>
            If you disagree with the data transfer or you consider your credit rating is not suitable for the chosen
payment method, we ask you to use another payment method. For more information about how PayPal
handles your personal data, please see the privacy policy at 
            </p>
            <p>
            <a href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full?locale.x=en_EN" rel="noopener noreferrer" target="_blank">https://www.paypal.com/de/webapps/mpp/ua/privacy-full?locale.x=en_EN</a> 
            </p>
            <h6 className={'text-uppercase color-maroon'}>W. Services of Google</h6>
            <p>
            Provider of the following Google services: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
94043, USA (hereafter “Google”).
            </p>
            <p>
            The legal basis for using the following Google services is our legitimate interest according to Art. 6 Para.
1 lit. f GDPR. Google has entered the EU/ U.S. PrivacyShield, is thereby committed to comply with
European privacy standards, and is in compliance with the requirements of the European Union to
legitimize the transfer of personal data to the U.S. Information on Google’s commitment concerning the
PrivacyShield can be found at 
            </p>
            <p>
            <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active" rel="noopener noreferrer" target="_blank">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active</a>
            </p>
            <p>
            For more information about how Google handles your personal data, please see the privacy policy of
Google at <a href="https://www.google.com/intl/en/policies/privacy/" rel="noopener noreferrer" target="_blank">https://www.google.com/intl/en/policies/privacy/</a>
            </p>
            <p>
            Information about Google’s use of data for advertising purposes, settings and options, can be found at
the following:
            </p>
            <ul>
                <li><a href="https://policies.google.com/technologies/partner-sites?hl=en" rel="noopener noreferrer" target="_blank">https://policies.google.com/technologies/partner-sites?hl=en</a></li>
                <li><a href="https://policies.google.com/technologies/ads?hl=en" rel="noopener noreferrer" target="_blank">https://policies.google.com/technologies/ads?hl=en</a></li>
                <li><a href="https://adssettings.google.com/authenticated" rel="noopener noreferrer" target="_blank">https://adssettings.google.com/authenticated</a></li>
                <li><a href="http://www.google.com/ads/preferences/" rel="noopener noreferrer" target="_blank">http://www.google.com/ads/preferences/</a></li>
            </ul>
            <h6 className={'text-uppercase color-maroon'}>1. Google Analytics</h6>
            <p>
            This Platform uses Google Analytics by Google. Google Analytics uses cookies. Google collects data
about the user visits of our Platform and the user behaviour. This data serves the purpose of ensuring
usability and a continuous optimization of our Platform, to measure the success of marketing measures
and to create statistical evaluations. In this context pseudonymised user profiles are created and cookies
are used. Information provided through a cookie about your usage of this Platform such as browser
type/version, utilized operating system, referrer-URL (before visited page), host name of the accessing
computer (IP address), and time of server request will be transmitted to and stored on a server of
Google in the U.S. User and use data will be deleted after 26 months. This information will also be
transferred to third parties if required by law or if third parties process this data on behalf of us or
Google. Under no circumstances will your IP address be merged with any other data of Google. IP
addresses will be anonymized. You can avoid cookie storage by adjusting your browser software 
settings. Without cookies, some functions of this Platform may not work. You can avoid the collection of
data generated by the cookie and related to your use of the Platform, as well as the collection and
processing of data by Google Analytics, by downloading and installing a browser plugin that can be
found at <a href="https://tools.google.com/dlpage/gaoptout?hl=en" rel="noopener noreferrer" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=en</a>. This add-on ensures that no visitor data
from your browser will be collected and stored by Google Analytics on future visits to this Platform.
Note that if you delete your cookies the add-on will be deleted as well and you may have to activate it
again.
            </p>
            <h6 className={'text-uppercase color-maroon'}>2. Demographic Characteristics by Google Analytics</h6>
            <p>
            Our Platform uses the function “demographic characteristics” as part of Google Analytics so reports can
be created containing information such as age, gender, and interests of Platform visitors. This data
comes from interest-based advertising from Google and third-party visitor data. This data cannot be
assigned to a specific person. The legal basis for the use of these Google services is our legitimate
interests to optimize and optimally market our Platform according to Art. 6 Para. 1 lit. f GDPR. You can
disable this feature at any time through the display settings in your Google account or generally prohibit
the collection of your data by Google Analytics as outlined above.
            </p>
            <h6 className={'text-uppercase color-maroon'}>3. Google Maps</h6>
            <p>
            This Platform uses Google Maps by Google to display site plans, maps, terrain data or geographic maps.
This service collects your IP address, notes Platform pages you have visited, and, if applicable, collects
other data required by Google to provide Google Maps (e.g., location data). Generated information will
be stored on a server in the U.S. This information will be transferred to third parties if required by law or
if third parties process these data on behalf of us or Google. The terms of use for Google Maps can be
found at <a href="https://developers.google.com/maps/terms-20180207" rel="noopener noreferrer" target="_blank">https://developers.google.com/maps/terms-20180207</a>.
            </p>
            <h6 className={'text-uppercase color-maroon'}>4. Google Tag Manager</h6>
            <p>
            This Platform uses Google Tag Manager for tagging. This service allows Platform tags to be managed by
a single interface. No cookies are used, and no personal data is collected. The Google Tag Manager
triggers other tags which may collect data. However, Google Tag Manager does not access this data. If
there is a deactivation at the domain or cookie level, it will remain in effect for all tracking tags if they
are implemented with the Google Tag Manager.
            </p>
            <h6 className={'text-uppercase color-maroon'}>X. Changes to this Privacy Policy</h6>
            <p>
            This Privacy Policy may be amended from time to time. Any modifications will be reflected online, so
check back periodically. If we make a significant or material change in the way we use your personal
information, we will make you aware by prominently posting a notice on our Platform.
            </p>
            <h6 className={'text-uppercase text-center color-maroon'}>Wholesale</h6>
            <p className={'text-center'}>
                Please contact us for whole sale price.
            </p>
            <div className="color-blue text-center">
                <a target="_blank" rel="noopener noreferrer" href={'https://www.google.com/maps/dir/40.7699456,-73.8770944/Land+Of+Buddha,+Inc.+128+Macdougal+St.+NY,+NY+-+10012,+USA/@40.7410663,-74.0084117,12z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x89c2599181537641:0xe484e67fa91bc594!2m2!1d-74.0002903!2d40.7300812'}>
                    Land Of Buddha, Inc.<br/>
                    128 Macdougal St.<br/>
                    NY, NY - 10012, USA
                </a><br/>
                <a title="Email Land Of Buddha" href="mailto:sales@lobny.com">
                    <i className="reg-fa fa fa-envelope-square" aria-hidden="true"></i></a>
                <a href="mailto:sales@lobny.com"> sales@lobny.com</a><br/>
                <a title="Call Land Of Buddha" href="tel:646-602-6588"><i
                    className="reg-fa fa fa-phone-square" aria-hidden="true"></i></a>
                <a href="tel:646-602-6588"> (646)-602-6588</a>
                <a href="tel:646-206-9466" className={'pl-2'}>(646)-206-9466</a>
            </div>
        </div>;
    }
}