import React from "react";
import { Modal } from 'react-bootstrap';

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className={'modal-header border-0 pb-0 pt-1 px-2 img-modal-header'}></Modal.Header>
            <Modal.Body className="py-0 px-4 pb-4">
                <img alt="images" src={props.img} style={{width: '100%'}}/>
            </Modal.Body>
        </Modal>
    );
}

export default MyVerticallyCenteredModal;