import React, {Component} from "react";
import CommonUtils from '../../../utils/common';

export default class Accessories extends Component {
    constructor(props) {
        super(props);
    }
    showModal = (img) => {
        this.props.setShowModal(true, img);
    };
    render() {
        return <div className={'row mx-0 oils-items'}>
            <div className={CommonUtils.tabContentClassName}>
            </div>
        </div>;
    }
}