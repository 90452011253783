const initialState = {
    activeHeader: window.location.pathname !== '/' ? window.location.pathname.replace('/', '') : 'home',
    activeCarousel: {
        label: 'Jewelry',
        link: '/jewelry'
    },
    meditationActive: window.location.pathname.indexOf('/meditation/') > -1
};
const carouselValues = {
    jewelry: {
        label: 'Jewelry',
        link: '/jewelry'
    },
    garments: {
        label: 'Garments',
        link: '/garments'
    }
};

const reducer = (state = initialState, action) => {
    const newState = {...state};
    console.log(newState);
    if (action.type === 'activeHeader') {
        newState.activeHeader = action.value;
    } else if (action.type === 'activeCarousel') {
        newState.activeCarousel = carouselValues[action.value];
    } else if (action.type === 'meditationActive') {
        newState.meditationActive = action.value;
    }
    console.log(newState);
    return newState;
};
export default reducer;