import React from "react";
import { Modal } from 'react-bootstrap';

function Covid19Modal(props) {
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton className={'modal-header border-0 pb-0 pt-1 px-2 img-modal-header'}></Modal.Header>
            <Modal.Body className="py-0 px-4 pb-4">
                <p>
                    <b>Dear Customers</b>,
                    <br/>
                    <br/>
                    Due to <span className="text-danger">COVID-19</span> pandemic, our store is still closed. However, we are available for shipping orders via phone. 
                    <br/>
                    Please call directly at <a href="tel:646-206-9466">(646)-206-9466</a> for any of your needs. 
                    <br/>Local customers may set up appointment to visit the store as well.
                    <br/>
                    <br/>
                    <span className="color-blue">FREE SHIPPING</span> for items over $200.
                    <br/>
                    <br/>
                    Thank you and stay safe,
                    <br/>
                    Land Of Buddha Family.
                </p>
            </Modal.Body>
        </Modal>
    );
}

export default Covid19Modal;