import React, {Component} from "react";
import one from '../../../assets/img/handicrafts/masks/1.jpeg';
import two from '../../../assets/img/handicrafts/masks/2.jpeg';
import three from '../../../assets/img/handicrafts/masks/3.jpeg';
import four from '../../../assets/img/handicrafts/masks/4.jpeg';
import five from '../../../assets/img/handicrafts/masks/5.jpeg';
import six from '../../../assets/img/handicrafts/masks/6.jpeg';
import seven from '../../../assets/img/handicrafts/masks/7.jpeg';
import eight from '../../../assets/img/handicrafts/masks/8.jpeg';
import nine from '../../../assets/img/handicrafts/masks/9.jpeg';
import CommonUtils from '../../../utils/common';

export default class Masks extends Component {
    constructor(props) {
        super(props);
    }
    showModal = (img) => {
        this.props.setShowModal(true, img);
    };
    render() {
        return <div className={'row mx-0 masks-items'}>
            <div className={CommonUtils.tabContentClassName}>
                <img src={one} onClick={(e) => {this.showModal(one)}}/>
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={two} onClick={(e) => {this.showModal(two)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={three} onClick={(e) => {this.showModal(three)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={four} onClick={(e) => {this.showModal(four)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={five} onClick={(e) => {this.showModal(five)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={six} onClick={(e) => {this.showModal(six)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={seven} onClick={(e) => {this.showModal(seven)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={eight} onClick={(e) => {this.showModal(eight)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={nine} onClick={(e) => {this.showModal(nine)}} />
            </div>
        </div>;
    }
}