import React, {Component} from "react";
import CommonUtils from '../../../utils/common';
import PrivateSessions from "./private-sessions/private-sessions";

export default class SoundHealing extends Component {
    constructor(props) {
        super();
        this.state = {
            activeTab: 'private',
            showModal: false,
            img: ''
        }
    }

    setActiveTab = (tab) => {
        this.setState({activeTab: tab})
    };

    setShowModal = (value, img) => {
        this.setState({img: img})
        this.setState({showModal: value})
    };

    render() {
        return <div id={'antiques'} className={CommonUtils.mainCompClassName}>
            <h5 className={CommonUtils.pageHeaderClassName}>Sound Healing</h5>
            <div className={'row mx-0'}>
                <div className={CommonUtils.decsriptionClassName}>
                    <p className="text-justify">
                        Music has long been known to be therapeutic and has been used as a sound healing technique since
                        ancient times. Sound healing is an ancient practice and sacred in Tibet, where it was first used around
                        the 12th century for meditation and rituals. Sound healing is an ancient meditative practice that uses
                        different musical instruments to create healing vibrations around the body and promote meditative
                        states. Sound healing therapy uses aspects of music to improve physical and emotional health and wellbeing. Sound is all around us, and inside of us. Sound healing uses vibrations to help reach a higher
                        frequency.
                    </p>
                    <p className="text-justify">
                        Sound Healing helps activate the body’s natural healing system for deep restorative healing on a cellular
                        and emotional level. The harmonizing effect produced by the sound creates a soothing effect
                        throughout the entire body. When different sound vibrations and frequencies are created, it impacts the
                        body and its meridians helping to promote deep relaxation, release emotional trauma, relieve stress,
                        and treat conditions like anxiety and depression.
                    </p>
                    <p className="text-justify">
                        During a sound healing session, various healing instruments, including but not limited to
                        Tibetan/Himalayan Singing Bowls, Gongs, Tingshas, Chimes, Rin Gong Singing Bowls, and Crystal Singing
                        Bowls are used to create a meditative and healing experience for the mind and body. The Singing Bowls
                        and Gongs can even be tuned to strategic frequencies for healing different parts of the body and mind.
                        Participants relax by lying down on a mat with their eyes closed while focusing on their breath. The
                        different vibrations and frequencies created heighten the receiver’s awareness and allows their bodies
                        to heal and re-tune from within. Typically, a certified sound healer/sound healing practitioner will work
                        with a client to find what they’re looking to achieve, and working with different frequencies and the
                        chakra system, can help restore harmony and balance back into the client’s physical and energetic body.
                        The client will simply receive and remain open as the sound healer uses different instruments and
                        techniques to “tune” the client. 
                    </p>
                    <p className="text-justify">
                        Sound healing is believed to help in the treatment of stress, depression, anxiety, post-traumatic stress
                        disorder (PTSD), sleep problems, and chronic pain. According to sound healing practitioners, through
                        vibration, the cells in the body are being harmonized by sound, thus promoting healing.
                    </p>
                    <p className="text-justify">
                        There are several techniques that are being used in sound healing. Below are the most popular ones
                        that are being used today:
                    </p>
                    <p className="text-justify">
                        1. Tibetan Bowls: Tibetan singing bowls are sometimes referred to as standing bells. Aside from being
                        used as a musical instrument, usually for new age music, they are also widely used for religious practices
                        like Buddhist meditation and chanting.
                    </p>
                    <p className="text-justify">
                        The most interesting role of Tibetan singing bowls, though, are its uses for sound healing. They are often
                        used to restore the normal vibrational frequency of the body because of the wide range of sounds they
                        produce. The healing process takes place through entraining our brainwaves to synchronize with the
                        perfect resonance of the bowls. “Unique tones create the perfect state for deep meditation, creative
                        thinking, and intuitive messages.” It is said that the deep sounds coming from Tibetan bowls will help in
                        healing several ailments including stress disorders, depression, and most forms of diseases.
                    </p>
                    <p className="text-justify">
                        2. Gong Bath: A gong bath or sound bath is a very ancient Asian sound healing technique. It is said to
                        produce an amazing sense of well-being through the vibration of all the water within the body. It is
                        called a “bath” because participants are going to bathe in sound waves.
                    </p>
                    <p className="text-justify">
                        Gong baths are like massages. So, it is a good alternative for people who are thrilled with the idea of a
                        massage but don’t really want to be touched.
                    </p>
                    <p className="text-justify">
                        Sound therapy can be used in conjunction with a myriad of other therapies and treatment plans
                        including meditation and reiki/energy healing. Incorporating sound therapy consistently has been
                        proven to improve quality of life and mood. It has been predicted that sound therapy or sound healing
                        will be the medicine of the future.
                    </p>
                </div>
                <div className={CommonUtils.tabClassName}>
                    <div className={'d-flex main-ul border-bottom'}>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'private' ? 'active' : '')} onClick={() => this.setActiveTab('private')}>
                            Private Sessions
                        </span>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'public' ? 'active' : '')} onClick={() => this.setActiveTab('public')}>
                            Public Sessions
                        </span>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'booking' ? 'active' : '')} onClick={() => this.setActiveTab('booking')}>
                            Booking
                        </span>
                    </div>

                    <div className={this.state.activeTab === 'private' ? '' : 'd-none'}>
                        <PrivateSessions setShowModal={this.setShowModal}/>
                    </div>
                </div>
            </div>
        </div>;
    }
}