import React, {Component} from "react";
import "./private-sessions.scss";
import MyVerticallyCenteredModal from "../../../../utils/modal";

export default class PrivateSessions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setShowModal: false,
            img: ''
        }
    }
    showModal = (img) => {
        this.props.setShowModal(true, img);
    };

    setShowModal = (value, img) => {
        this.setState({img: img});
        this.setState({setShowModal: value})
    };
    render() {
        return <div className={'row mx-0 justify-content-sp-evenly'}>
            <div className={'image-div'}>
                <img src="https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture1.jpg" alt="img" onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture1.jpg")}}/>
                <span className={'description'}>
                    <h6 className="mb-0 text-uppercase">1. Crystal Reiki</h6>
                    <small>Crystals on body</small><br/>
                    Chakra Clearing/Balancing<br/>
                    $50/30 min
                </span>
            </div>
            <div className={'image-div'}>
                <img src="https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture2.jpg" alt="img" onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture2.jpg")}}/>
                <span className={'description'}>
                    <h6 className="mb-0 text-uppercase">2. Singing Bowl Sound Bath</h6>
                    <small>No bowls on body</small><br/>
                    $50/30 min
                </span>
            </div>
            <div className={'image-div'}>
                <img src="https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture3.jpg" alt="img" onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture3.jpg")}}/>
                <span className={'description'}>
                    <h6 className="mb-0">3. SHAMANIC DRUM HEALING, MEDITATION & VIBRATIONAL THERAPY</h6>
                    <small>Exposure to loud native drumming</small><br/>
                    $50/30 min
                </span>
            </div>
            <div className={'image-div'}>
                <img src="https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture4.jpg" alt="img" onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture4.jpg")}}/>
                <span className={'description'}>
                    <h6 className="mb-0">4. VIBRATIONAL WATER & SOUND THERAPY MASSAGE</h6>
                    <small>Bowls on body. Small water droplets may get on body.</small><br/>
                    Chakra Clearing/Balancing<br/>
                    $60/30 min         $100/60 min
                </span>
            </div>
            <div className={'image-div-2'}>
                <img className={'pull-left'} src="https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture2.jpg" alt="img" onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture2.jpg")}}/>
                <img className={'pull-right'} src="https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture6.jpg" alt="img" onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture6.jpg")}}/>
                <span className={'description d-inline-block'}>
                    <h6 className="mb-0">5. SINGING BOWL SOUND BATH & VIBRATIONAL THERAPY</h6>
                    <small>Bowls on body</small><br/>
                    $100/60 min
                </span>
            </div>
            <div className={'image-div-3'}>
                <img className={'pull-left'} src="https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture3.jpg" alt="img" onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture3.jpg")}}/>
                
                <img className={'pull-left'} style={{'margin-left': '3%'}} src="https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture2.jpg" alt="img" onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture2.jpg")}}/>
                
                <img className={'pull-right'} src="https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture6.jpg" alt="img" onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture6.jpg")}}/>
                <span className={'description d-inline-block'}>
                    <h6 className="mb-0">6. SHAMANIC DRUM HEALING + SINGING BOWL SOUND BATH & VIBRATIONAL THERAPY</h6>
                    <small>Exposure to loud native drumming + Bowls on body</small><br/>
                    $125/75 min
                </span>
            </div>
            <div className={'image-div-3'}>
                <img className={'pull-left'} src="https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture1.jpg" alt="img" onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture1.jpg")}}/>
                
                <img className={'pull-left'} style={{'margin-left': '3%'}} src="https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture2.jpg" alt="img" onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture2.jpg")}}/>
                
                <img className={'pull-right'} src="https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture6.jpg" alt="img" onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture6.jpg")}}/>
                <span className={'description d-inline-block'}>
                    <h6 className="mb-0">7. CRYSTAL REIKI + SINGING BOWL SOUND BATH & VIBRATIONAL THERAPY</h6>
                    <small>Crystals + Bowls on body</small><br/>
                    $125/75 min
                </span>
            </div>
            <div className={'image-div-4'}>
                
                <img className={'pull-left'} src="https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture1.jpg" alt="img" onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture1.jpg")}}/>
                    
                <img className={'pull-left'} style={{'margin-left': '3%'}} src="https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture3.jpg" alt="img" onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture3.jpg")}}/>
                    
                <img className={'pull-left'} style={{'margin-left': '3%'}} src="https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture2.jpg" alt="img" onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture2.jpg")}}/>
                <img className={'pull-right'} src="https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture6.jpg" alt="img" onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/sound-healing/Picture6.jpg")}}/>
                <span className={'description d-inline-block'}>
                    <h6 className="mb-0">8. CRYSTAL REIKI + SHAMANIC DRUM HEALING + SINGING BOWL SOUND BATH & VIBRATIONAL THERAPY</h6>
                    <small>Crystals + Bowls on body + Exposure to loud native drumming: The ultimate vibrational healing experience</small><br/>
                    $150/90 min
                </span>
            </div>
            <MyVerticallyCenteredModal
                    show={this.state.setShowModal}
                    img={this.state.img}
                    onHide={() => this.setShowModal(false, '')}
                />
        </div>;
    }
}