import React, {Component} from "react";
import one from '../../../assets/img/jewelry/earRings/1.jpg';
import two from '../../../assets/img/jewelry/earRings/2.jpg';
import three from '../../../assets/img/jewelry/earRings/3.jpg';
import six from '../../../assets/img/jewelry/earRings/6.jpg';
import seven from '../../../assets/img/jewelry/earRings/7.jpg';
import eight from '../../../assets/img/jewelry/earRings/8.jpg';
import nine from '../../../assets/img/jewelry/earRings/9.jpg';
import CommonUtils from '../../../utils/common';

export default class EarRings extends Component {
    constructor(props) {
        super(props);
    }
    showModal = (img) => {
        this.props.setShowModal(true, img);
    };
    render() {
        return <div className={'row mx-0'}>
            <div className={CommonUtils.tabContentClassName}>
                <img src={one} onClick={(e) => {this.showModal(one)}}/>
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={two} onClick={(e) => {this.showModal(two)}}/>
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={three} onClick={(e) => {this.showModal(three)}}/>
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={six} onClick={(e) => {this.showModal(six)}}/>
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={seven} onClick={(e) => {this.showModal(seven)}}/>
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={eight} onClick={(e) => {this.showModal(eight)}}/>
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={nine} onClick={(e) => {this.showModal(nine)}}/>
            </div>
        </div>;
    }
}