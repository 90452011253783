import React, {Component, useState} from "react";
import one from '../../assets/img/handicrafts/tibetancarpets/tc1.jpg';
import two from '../../assets/img/handicrafts/tibetancarpets/tc2.jpg';
import three from '../../assets/img/handicrafts/tibetancarpets/tc3.jpg';
import CommonUtils from '../../utils/common';
import MyVerticallyCenteredModal from "../../utils/modal";

export default class Books extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '',
            setShowModal: false,
            img: ''
        }
    }

    setActiveTab = (tab) => {
        this.setState({activeTab: tab})
    };

    setShowModal = (value, img) => {
        this.setState({img: img});
        this.setState({setShowModal: value})
    };
    render() {
        return <div id={'incense-oils'} className={CommonUtils.mainCompClassName}>
                <h5 className={CommonUtils.pageHeaderClassName}>Tibetan Carpets</h5>
                <div className={'row mx-0'}>
                    <div className={CommonUtils.decsriptionClassName}>
                        <p className="text-justify">
                            The Tibetan carpets contain a very high degree of hand processing and qualities
                            ranging from 60-150 knots per square inch. Regularity safe guards are in place to ensure that
                            only highest quality fleece wool is imported for use in these carpets.
                        </p>
                        <h6 className="text-uppercase mb-2 color-maroon">CARPETS' History</h6>
                        <p className="text-justify">
                            Perhaps not so well publicized on the history of handicraft of carpet requiring manual and
                            artistic skill is moved by well research and study. The art of carpet weaving is no more a new
                            handicraft as it had been practicing since a long time. After 1959, it became Hobson's choice
                            and many of them left for neighboring countries like Nepal and India. The adorning beds with beautiful woolen carpet in
                            Tibetan houses harmonize with the history of carpets. Generally, Tibetan refugees started
                            establishing carpet factory and the demand for which was no doubt much strong in the
                            national as well as in the international markets. 
                        </p>
                    </div>
                    <div className={CommonUtils.tabClassName}>
                    <div className={'row books-items mx-0'}>
                        <div className={CommonUtils.tabContentClassName}>
                            <img src={one} onClick={(e) => {this.setShowModal(true, one)}}/>
                        </div>
                        <div className={CommonUtils.tabContentClassName}>
                            <img src={two} onClick={(e) => {this.setShowModal(true, two)}} />
                        </div>
                        <div className={CommonUtils.tabContentClassName}>
                            <img src={three} onClick={(e) => {this.setShowModal(true, three)}} />
                        </div>
                        </div>
                    </div>
                </div>
                <MyVerticallyCenteredModal
                    show={this.state.setShowModal}
                    img={this.state.img}
                    onHide={() => this.setShowModal(false, '')}
                />
            </div>;
    }
}