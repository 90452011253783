import React, { Component } from "react";
import CommonUtils from "../../utils/common";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyles = {
    width: '95%',
    height: '450px'
};

export class StoreInfo extends Component {
    gotoMap = (store) => {
        if (store === 'first') {
            window.open('https://www.google.com/maps/place/128+Macdougal+St,+New+York,+NY+10012/@40.7300842,-74.0024387,17z/data=!3m1!4b1!4m5!3m4!1s0x89c259918474ef39:0xc86243eaffa3b00e!8m2!3d40.7300842!4d-74.00025', '_blank');
            return;
        } 
        window.open('https://www.google.com/maps/place/11+St+Marks+Pl,+New+York,+NY+10003/@40.7294474,-73.9911832,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2599b8870e65f:0x4e5f60dcca7872b5!8m2!3d40.7294474!4d-73.9889945', '_blank');
    };
    render () {
        return <div id={'store-info'} className={'p-md-4 p-1'}>
            <h5 className={CommonUtils.pageHeaderClassName}>Store Location, Hours, Direction and Map</h5>
            <h6 className={'text-uppercase color-maroon'}>Store Address</h6>
            <div className="mb-3">
                Address 1:
                <br/>
                <a target="_blank" rel="noopener noreferrer" href={'https://www.google.com/maps/place/128+Macdougal+St,+New+York,+NY+10012/@40.7300842,-74.0024387,17z/data=!3m1!4b1!4m5!3m4!1s0x89c259918474ef39:0xc86243eaffa3b00e!8m2!3d40.7300842!4d-74.00025'}>
                    128 Macdougal St.<br/>
                    NY, NY - 10012, USA
                </a>
                <br/>
                Address 2:
                <br/>
                <a target="_blank" rel="noopener noreferrer" href={'https://www.google.com/maps/place/11+St+Marks+Pl,+New+York,+NY+10003/@40.7294474,-73.9911832,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2599b8870e65f:0x4e5f60dcca7872b5!8m2!3d40.7294474!4d-73.9889945'}>
                    11 Saint Mark Place<br/>
                    NY, NY - 10003, USA
                </a>
            </div>
            <h6 className={'text-uppercase color-maroon'}>Store Hours</h6>
            <p>
                12:00 Noon to 10:00 pm Everyday
            </p>
            <div className="map-section">
                <Map
                    google={this.props.google}
                    zoom={14}
                    style={mapStyles}
                    fullscreenControl={false}
                    mapTypeControl={false}
                    streetViewControl={false}
                    initialCenter={{
                        lat: 40.730099,
                        lng: -74.000284
                      }}
                >
                    <Marker position={{ lat: 40.730099, lng: -74.000284}} onClick={(e) => {this.gotoMap('first')}} />
                    <Marker position={{ lat: 40.729450, lng: -73.988968}} onClick={(e) => {this.gotoMap('second')}} />
                </Map>
            </div>
        </div>;
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBIBx2aqV8CXTwyV0VzKh_fH5FpaZmYFwg'
  })(StoreInfo);