import React, {Component, useState} from 'react';
import './navbar.scss';
import { Link } from 'react-router-dom';
import {connect} from "react-redux";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true
        };
    }
    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    };

    closeNavbar = () => {
        this.setState({
            collapsed: true
        });
    };

    render() {
        return <nav className="navbar navbar-expand lob-bg-light horizontal-nav">
            <div className={"navbar-collapse"}>
                {this.props.meditationActive &&
                <ul className="navbar-nav ml-auto">
                    <li className={'nav-item ' + (this.props.activeHeader === 'store-policy' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('store-policy')}>
                        <Link className="nav-link" to="/store-policy">Policy</Link>
                    </li>
                    <li className={'nav-item ' + (this.props.activeHeader === 'contact-us' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('contact-us')}>
                        <Link className="nav-link" to="/contact-us">Contact Us</Link>
                    </li>
                </ul>
                }
                {!this.props.meditationActive &&
                <ul className="navbar-nav ml-auto">
                    <li className={'nav-item ' + (this.props.activeHeader === 'home' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('home')}>
                        <Link className="nav-link" to="/home">Home</Link>
                    </li>
                    <li className={'nav-item ' + (this.props.activeHeader === 'store-policy' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('store-policy')}>
                        <Link className="nav-link" to="/store-policy">Policy</Link>
                    </li>
                    <li className={'nav-item ' + (this.props.activeHeader === 'contact-us' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('contact-us')}>
                        <Link className="nav-link" to="/contact-us">Contact Us</Link>
                    </li>
                    <li className={'nav-item ' + (this.props.activeHeader === 'links' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('links')}>
                        <Link className="nav-link" to="/links">Links</Link>
                    </li>
                </ul>
                }
            </div>
        </nav>;
    }
}

const mapStateToProps = (state) => {
    return {
        activeHeader: state.activeHeader,
        activeCarousel: state.activeCarousel,
        meditationActive: state.meditationActive
    }
};
const mapDispatchToProps = (dispach) => {
    return {
        activeHeaderChanged: (value) => dispach({type: 'activeHeader', value: value}),
        activeCarouselChanged: (value) => dispach({type: 'activeCarousel', value: value})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);