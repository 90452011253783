import React, {Component, useState} from "react";
import Pashmina from './pashmina/pashmina';
import RecycledSilk from './recycledsilk/recycledsilk';
import Hemp from './hemp/hemp';
import Woolen from './woolen/woolen';
import Clothing from './clothing/clothing';
import './garments.scss';
import CommonUtils from "../../utils/common";
import MyVerticallyCenteredModal from "../../utils/modal";
import Pendants from "../jewelry/pendants/pendants";

export default class Garments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'pashmina',
            showModal: false,
            img: ''
        }
    }

    setActiveTab = (tab) => {
        this.setState({activeTab: tab})
    };

    setShowModal = (value, img) => {
        this.setState({img: img});
        this.setState({showModal: value})
    };

    render() {
        return <div id={'garments'} className={CommonUtils.mainCompClassName}>
            <h5 className={CommonUtils.pageHeaderClassName}>Garments</h5>
            <div className={'row mx-0'}>
                <div className={CommonUtils.decsriptionClassName}>
                    <div>
                        <h6 className="text-uppercase mb-2 color-maroon">PASHMINA</h6>
                        <p className="text-justify">
                            Pashmina, from the Persian word for
                            wool, is popularly known in  the west as
                            cashmere wool, from the old spelling for
                            Kashmir. The fine wool comes from the
                            undercoat of Himalayan mountain goat,
                            raised by semi-nomadic herders. It yields
                            3-8 ounces of fiber per year. The Tibetan
                            plateau provides the ideal environment
                            for pashmina growth. The fiber is
                            exceptionally absorbent and it dyes very
                            easily and deeply. In recent years,
                            pashmina shawl has been in fashion all
                            over the world for its luxurious rarity, its
                            incomparable softness, warmth and
                            durability.
                        </p>
                        <h6 className="text-uppercase mb-2 color-maroon">Recycled Silk</h6>
                        <p className="text-justify">
                            This unique silk yarn is made of remnant fibers from the industrial mills in India. These fibers are
                            collected and handspun in Nepal. The colorfast industrially dyed fibers show the infinite variety of
                            rich jewel colors found in Indian saris. Each handspun hank is unique, so there are no dyelots.
                            Because it is handspun, the yarn varies in thickness and contains some overtwisted areas.
                        </p>
                    </div>
                </div>
                <div className={CommonUtils.tabClassName}>
                    <div className={'d-flex main-ul border-bottom'}>
                    <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'pashmina' ? 'active' : '')} onClick={() => this.setActiveTab('pashmina')}>
                        Pashmina
                    </span>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'recycledSilk' ? 'active' : '')} onClick={() => this.setActiveTab('recycledSilk')}>
                        Recycled Silk
                    </span>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'woolen' ? 'active' : '')} onClick={() => this.setActiveTab('woolen')}>
                        Woolen
                    </span>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'hemp' ? 'active' : '')} onClick={() => this.setActiveTab('hemp')}>
                        Hemp
                    </span>
                    {/* <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'clothing' ? 'active' : '')} onClick={() => this.setActiveTab('clothing')}>
                        Clothing
                    </span> */}
                    </div>
                    <div className={this.state.activeTab === 'pashmina' ? '' : 'd-none'}>
                        <Pashmina setShowModal={this.setShowModal} />
                    </div>
                    <div className={this.state.activeTab === 'recycledSilk' ? '' : 'd-none'}>
                        <RecycledSilk setShowModal={this.setShowModal} />
                    </div>
                    <div className={this.state.activeTab === 'hemp' ? '' : 'd-none'}>
                        <Hemp setShowModal={this.setShowModal} />
                    </div>
                    <div className={this.state.activeTab === 'woolen' ? '' : 'd-none'}>
                        <Woolen setShowModal={this.setShowModal} />
                    </div>
                    {/* <div className={this.state.activeTab === 'clothing' ? '' : 'd-none'}>
                        <Clothing setShowModal={this.setShowModal}/>
                    </div> */}
                </div>
            </div>
            <MyVerticallyCenteredModal
                show={this.state.showModal}
                img={this.state.img}
                onHide={() => this.setShowModal(false)}
            />
        </div>;
    }
}