import React, {Component, useState} from "react";
import Accessories from './accessories/accessories';
import Indian from './indian/indian';
import Nepalese from './nepalese/nepalese';
import Tibetan from './tibetan/tibetan';
import Oils from './oils/oils';
import CommonUtils from "../../utils/common";
import MyVerticallyCenteredModal from "../../utils/modal";

export default class IncenseOils extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'tibetan',
            showModal: false,
            img: ''
        }
    }

    setActiveTab = (tab) => {
        this.setState({activeTab: tab})
    };

    setShowModal = (value, img) => {
        this.setState({img: img})
        this.setState({showModal: value})
    };

    render() {
        return <div id={'incense-oils'} className={CommonUtils.mainCompClassName}>
            <h5 className={CommonUtils.pageHeaderClassName}>Incense & Essential oils</h5>
            <div className={'row mx-0'}>
                <div className={CommonUtils.decsriptionClassName}>
                    <div>
                        <h6 className="text-uppercase mb-2 color-maroon">Tibetan incense</h6>
                        <p className="text-justify">
                            Tibetan incense are collectively made out of various aromatic herbs founds in highly altitude regions of
                            Tibet and plain of Nepal. These herbal medicated incense is made under the strict control of traditional
                            Tibetan hand made incense following the key instructions of renowned herbs masters. This incense is
                            widely used by Buddhist’s and by others for the purpose of meditation, Relaxation, purifying and
                            offerings. The combination of the genuine scented substances are likewise- red and white
                            sandalwood, kusum flower, dellium, holy basil, juniper, cedar wood, spike- nard, agar wood, cloves,
                            nutmeg.
                        </p>
                        <h6 className="text-uppercase mb-2 color-maroon">Indian incense</h6>
                        <p className="text-justify">
                            Nag Champa incense is made from fragrant champak flowers
                            and other creates a sweet enchanting fragrance which will linger
                            long after the sticks have finished burning. The champak flower
                            is renowned for its cooling effect on the mind. It is often used
                            along with Sandalwood in religious rituals to purify and calm the
                            mind before worship. This is the single-most popular incense
                            brand in the world.
                        </p>
                    </div>
                </div>
                <div className={CommonUtils.tabClassName}>
                    <div className={'d-flex main-ul border-bottom'}>
                    <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'tibetan' ? 'active' : '')} onClick={() => this.setActiveTab('tibetan')}>
                        Tibetan
                    </span>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'nepalese' ? 'active' : '')} onClick={() => this.setActiveTab('nepalese')}>
                        Nepalese
                    </span>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'indian' ? 'active' : '')} onClick={() => this.setActiveTab('indian')}>
                        Indian
                    </span>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'accessories' ? 'active' : '')} onClick={() => this.setActiveTab('accessories')}>
                        Accessories
                    </span>
                    {/*    <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'oils' ? 'active' : '')} onClick={() => this.setActiveTab('oils')}>*/}
                    {/*    Essential Oils*/}
                    {/*</span>*/}
                    </div>
                    <div className={this.state.activeTab === 'tibetan' ? '' : 'd-none'}>
                        <Tibetan setShowModal={this.setShowModal} />
                    </div>
                    <div className={this.state.activeTab === 'nepalese' ? '' : 'd-none'}>
                        <Nepalese setShowModal={this.setShowModal} />
                    </div>
                    <div className={this.state.activeTab === 'indian' ? '' : 'd-none'}>
                        <Indian setShowModal={this.setShowModal} />
                    </div>
                    <div className={this.state.activeTab === 'accessories' ? '' : 'd-none'}>
                        <Accessories setShowModal={this.setShowModal} />
                    </div>
                    <div className={this.state.activeTab === 'oils' ? '' : 'd-none'}>
                        <Oils setShowModal={this.setShowModal} />
                    </div>
                </div>
            </div>
            <MyVerticallyCenteredModal
                show={this.state.showModal}
                img={this.state.img}
                onHide={() => this.setShowModal(false, '')}
            />
        </div>;
    }
}