import React, {Component} from "react";
import one from '../../../assets/img/singingbowls/copper/1.jpg';
import two from '../../../assets/img/singingbowls/copper/2.jpg';
import three from '../../../assets/img/singingbowls/copper/3.jpg';
import four from '../../../assets/img/singingbowls/copper/4.jpg';
import five from '../../../assets/img/singingbowls/copper/5.jpg';
import CommonUtils from '../../../utils/common';

export default class Copper extends Component {
    constructor(props) {
        super(props);
    }
    showModal = (img) => {
        this.props.setShowModal(true, img);
    };
    render() {
        return <div className={'row mx-0 sb-copper-items'}>
            <div className={CommonUtils.tabContentClassName}>
                <img src={one} onClick={(e) => {this.showModal(one)}}/>
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={two} onClick={(e) => {this.showModal(two)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={three} onClick={(e) => {this.showModal(three)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={four} onClick={(e) => {this.showModal(four)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={five} onClick={(e) => {this.showModal(five)}} />
            </div>
        </div>;
    }
}