import React, {Component} from "react";
import CommonUtils from '../../../utils/common';
import one from "../../../assets/img/religiousitems/prayerbeads/1.jpeg";
import two from "../../../assets/img/religiousitems/prayerbeads/2.jpeg";
import three from "../../../assets/img/religiousitems/prayerbeads/3.jpeg";
import four from "../../../assets/img/religiousitems/prayerbeads/4.jpeg";
import five from "../../../assets/img/religiousitems/prayerbeads/5.jpeg";
import six from "../../../assets/img/religiousitems/prayerbeads/6.jpeg";
import seven from "../../../assets/img/religiousitems/prayerbeads/7.jpeg";
import eight from "../../../assets/img/religiousitems/prayerbeads/8.jpeg";
import nine from "../../../assets/img/religiousitems/prayerbeads/9.jpeg";
import ten from "../../../assets/img/religiousitems/prayerbeads/10.jpeg";
import eleven from "../../../assets/img/religiousitems/prayerbeads/11.jpeg";

export default class Prayerbeads extends Component {
    constructor(props) {
        super(props);
    }
    showModal = (img) => {
        this.props.setShowModal(true, img);
    };
    render() {
        return <div className={'row mx-0 prayer-beats-items'}>
            <div className={CommonUtils.tabContentClassName}>
                <img src={one} onClick={(e) => {this.showModal(one)}}/>
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={two} onClick={(e) => {this.showModal(two)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={three} onClick={(e) => {this.showModal(three)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={four} onClick={(e) => {this.showModal(four)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={five} onClick={(e) => {this.showModal(five)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={six} onClick={(e) => {this.showModal(six)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={seven} onClick={(e) => {this.showModal(seven)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={eight} onClick={(e) => {this.showModal(eight)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={nine} onClick={(e) => {this.showModal(nine)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={ten} onClick={(e) => {this.showModal(ten)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={eleven} onClick={(e) => {this.showModal(eleven)}} />
            </div>
        </div>;
    }
}