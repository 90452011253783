import React, {Component} from "react";
import one from '../../../assets/img/religiousitems/prayerflags/1.jpeg';
import two from '../../../assets/img/religiousitems/prayerflags/2.jpg';
import CommonUtils from '../../../utils/common';

export default class PrayerFlags extends Component {
    constructor(props) {
        super(props);
    }
    showModal = (img) => {
        this.props.setShowModal(true, img);
    };
    render() {
        return <div className={'row mx-0 prayer-flags-items'}>
            <div className={CommonUtils.tabContentClassName}>
                <img src={one} onClick={(e) => {this.showModal(one)}}/>
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={two} onClick={(e) => {this.showModal(two)}} />
            </div>
        </div>;
    }
}