import React, {Component} from "react";
import one from '../../../assets/img/antiques/1.jpg';
import two from '../../../assets/img/antiques/2.jpg';
import three from '../../../assets/img/antiques/3.jpg';
import four from '../../../assets/img/antiques/4.jpg';
import five from '../../../assets/img/antiques/5.jpg';
import six from '../../../assets/img/antiques/6.jpg';
import seven from '../../../assets/img/antiques/7.jpg';
import eight from '../../../assets/img/antiques/8.jpg';
import nine from '../../../assets/img/antiques/9.jpg';
import ten from '../../../assets/img/antiques/10.jpg';
import eleven from '../../../assets/img/antiques/11.jpg';
import CommonUtils from '../../../utils/common';

export default class Metals extends Component {
    constructor(props) {
        super(props);
    }
    showModal = (img) => {
        this.props.setShowModal(true, img);
    };
    render() {
        return <div className={'row mx-0 tibetan-carpets-items'}>
            <div className={CommonUtils.tabContentClassName}>
                <img src={one} onClick={(e) => {this.showModal(one)}}/>
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={two} onClick={(e) => {this.showModal(two)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={three} onClick={(e) => {this.showModal(three)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={four} onClick={(e) => {this.showModal(four)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={five} onClick={(e) => {this.showModal(five)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={six} onClick={(e) => {this.showModal(six)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={seven} onClick={(e) => {this.showModal(seven)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={eight} onClick={(e) => {this.showModal(eight)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={nine} onClick={(e) => {this.showModal(nine)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={ten} onClick={(e) => {this.showModal(ten)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={eleven} onClick={(e) => {this.showModal(eleven)}} />
            </div>
        </div>;
    }
}