import React, {Component} from "react";
import './home.scss';
import mane from '../../assets/gif/maniwheel-anim.gif';
import video from '../../assets/videos/home.mp4';
import CommonUtils from "../../utils/common";
import Covid19Modal from "../../utils/covid19Modal";

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBanner: window.location.pathname === '/TibetanStoreNYC.html' ? true : false,
            covid19Message: false
        };
    }
    render() {
        return <div id={'home'} className={CommonUtils.mainCompClassName}>
            <div className={'row mx-0'}>
            <div className={this.state.showBanner ? 'text-center mb-3 w-100' : 'd-none'}>
                <div className="banner border rounded p-4 mb-3 text-center d-inline-block shadow-lg" style={{background: '#660000', color: '#FFF'}}>
                    Welcome to
                    <br/>
                    <span className="text-primary font-weight-bold">Land Of Buddha</span><br/>
                    <span className="text-primary font-weight-bold">Tibetan Store in NYC</span><br/>
                    Huge selection of all types of <br/>
                    Singing Bowls, Hand made Jewelry, Incense,<br/>
                    Prayer beads, and Buddhism & Meditation related<br/>
                    supplies.
                </div>
            </div>
                    <span>
                        <div className='text-justify'>
                            <img alt="images" className={'pull-left mr-md-3 mr-1 mane'} src={mane} />
                            <video className="pull-right ml-md-3 ml-1" width="40%" height="auto" autoPlay={true} loop={true} muted={true}>
                                <source src={video} type="video/mp4" />
                                        Your browser does not support the video tag.
                            </video>
                            Land Of Buddha specializes in Antiques, Silver and Gold Jewelry, Natural Stones,
                            Hand crafted beads, Tibetan Carpets, Books, Audio Books, DVD and CDs related to
                            Buddhism and Yoga, Buddhist Religious Items such as: Statues, Thangkas, Singing
                            Bowls (Antique, seven metal, copper, Brass and all kinds of Crystal singing bowls),
                            Incense and Incense burners, Prayer Wheels, Prayer Beads, Prayer Flags etc.,
                            Clothing such as: Traditional Tibetan Dress, Silk, Woolen and Pashmina shawls and
                            scarves, and finally all kinds of Himalayan Handcrafts. Most items come from Tibet,
                            Nepal, and Bhutan.  We are both retailer and wholesaler.
                            <br/>
                            Land of Buddha is a among very few Tibetan/Buddhist stores in the United States,
                            located in the West Village section of Manhattan, NY.  West Village is one of the
                            liveliest tourist locations. Within a block of our store are : <br/>
                            <ul className="home-links w-100 pl-0">
                                <li><i className="fa fa-circle" aria-hidden="true"></i><a href="http://www.cafewha.com" target="_blank">Caffe Wha</a></li>
                                <li><i className="fa fa-circle" aria-hidden="true"></i><a href="http://www.cafereggio.com/" target="_blank">Caffe Reggio</a></li>
                                <li><i className="fa fa-circle" aria-hidden="true"></i><a href="http://www.comedycellar.com/hom.shtml" target="_blank">Comedy Cellar</a></li>
                                <li><i className="fa fa-circle" aria-hidden="true"></i><a href="http://www.theplayerstheatre.com/" target="_blank">Players Theatre</a></li>
                                <li><i className="fa fa-circle" aria-hidden="true"></i><a href="http://newyork.citysearch.com/profile/7109102" target="_blank">Olive Tree Caffee</a></li>
                                <li><i className="fa fa-circle" aria-hidden="true"></i><a href="http://www.mamouns.com/locations" target="_blank">Mamoun's Falafel</a></li>
                                <li><i className="fa fa-circle" aria-hidden="true"></i><a href="http://www.bluenote.net/newyork/index.shtml" target="_blank">Blue Note Jazz Bar</a></li>
                                <li><i className="fa fa-circle" aria-hidden="true"></i><a href="http://www.nyu.edu" target="_blank">New York University</a></li>
                            </ul>
                            and Washington Square Park, all very very famous in the City.  In the neighborhood
                            there are many small boutiques, tattoo parlors, all kinds of restaurants, bars, and
                            dance clubs.  Most weekends feature all-night events around this area.  If you are a
                            tourist or a visitor from out of town, you should not miss this exciting neighborhood.<br/>
                            So, please be sure to visit us while you are in New York and take a first hand look at
                            our authentic Himalayan handicrafts, jewelry, museum quality antiques and beautifully
                            hand painted Buddhist art works.
                        </div>
                    </span>
                </div> 
                <Covid19Modal
                    show={this.state.covid19Message}
                    onHide={() => this.setState({covid19Message: false})}
                />
        </div>;
    }
}
