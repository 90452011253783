import React, {Component, useState} from "react";
import Masks from './masks/masks';
import './handicrafts.scss';
import CommonUtils from "../../utils/common";
import MyVerticallyCenteredModal from "../../utils/modal";
import Metals from './metals/metals';

export default class ReligiousItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'masks',
            showModal: false,
            img: ''
        }
    }

    setActiveTab = (tab) => {
        this.setState({activeTab: tab})
    };

    setShowModal = (value, img) => {
        this.setState({img: img})
        this.setState({showModal: value})
    };

    render() {
        return <div id={'handicrafts'} className={CommonUtils.mainCompClassName}>
            <h5 className={CommonUtils.pageHeaderClassName}>Handicrafts</h5>
            <div className={'row mx-0'}>
                <div className={CommonUtils.decsriptionClassName}>
                    <div>
                        <h6 className="text-uppercase mb-2 color-maroon">Masks</h6>
                        <p className="text-justify">
                            Hand made wooden masks from Nepal and Tibet
                        </p>
                    </div>
                </div>
                <div className={CommonUtils.tabClassName}>
                    <div className={'d-flex main-ul border-bottom'}>
                    <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'masks' ? 'active' : '')} onClick={() => this.setActiveTab('masks')}>
                        Masks
                    </span>
                    <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'metals' ? 'active' : '')} onClick={() => this.setActiveTab('metals')}>
                        Metals
                    </span>
                    </div>
                    <div className={this.state.activeTab === 'masks' ? '' : 'd-none'}>
                        <Masks setShowModal={this.setShowModal}/>
                    </div>
                    <div className={this.state.activeTab === 'metals' ? '' : 'd-none'}>
                        <Metals setShowModal={this.setShowModal}/>
                    </div>
                </div>
            </div>
            <MyVerticallyCenteredModal
                show={this.state.showModal}
                img={this.state.img}
                onHide={() => this.setShowModal(false, '')}
            />
        </div>;
    }
}