import React, {Component} from "react";
import CommonUtils from '../../utils/common';
import MyVerticallyCenteredModal from "../../utils/modal";

export default class Antiques extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'books',
            setShowModal: false,
            img: ''
        }
    }

    setActiveTab = (tab) => {
        this.setState({activeTab: tab})
    };

    setShowModal = (value, img) => {
        this.setState({img: img});
        this.setState({setShowModal: value})
    };
    render() {
        return <div id={'antiques'} className={CommonUtils.mainCompClassName}>
            <h5 className={CommonUtils.pageHeaderClassName}>Antiques</h5>
            <div className={'row mx-0'}>
                <div className={CommonUtils.decsriptionClassName}>
                    <p className="text-justify">
                        We proudly boasts number of Nepali and Tibetan antiques, some of them being more 100 years old. You can find different kinds of Antique Metal goods
                        like statues, masks and pots.
                    </p>
                </div>
                <div className={CommonUtils.tabClassName}>
                    <div className={'row antiques-items mx-0'}>
                        
                    </div>
                </div>
            </div>
            <MyVerticallyCenteredModal
                show={this.state.setShowModal}
                img={this.state.img}
                onHide={() => this.setShowModal(false, '')}
            />
        </div>;
    }
}