import React, {Component, useState} from "react";
import Thangkas from './thangkas/thangkas';
import PrayerBeads from './prayerbeads/prayerbeads';
import PrayerFlags from './prayerflags/prayerflags';
import PrayerWheels from './prayerwheels/prayerwheels';
import Statues from './statues/statues';
import './religiousitems.scss';
import CommonUtils from "../../utils/common";
import MyVerticallyCenteredModal from "../../utils/modal";
import Pendants from "../jewelry/pendants/pendants";

export default class ReligiousItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'thangkas',
            showModal: false,
            img: ''
        }
    }

    setActiveTab = (tab) => {
        this.setState({activeTab: tab})
    };

    setShowModal = (value, img) => {
        this.setState({img: img})
        this.setState({showModal: value})
    };

    render() {
        return <div id={'garments'} className={CommonUtils.mainCompClassName}>
            <h5 className={CommonUtils.pageHeaderClassName}>Religious Items</h5>
            <div className={'row mx-0'}>
                <div className={CommonUtils.decsriptionClassName}>
                    <div>
                        <h6 className="text-uppercase mb-2 color-maroon">Thangkas</h6>
                        <p className="text-justify">
                            Ever since the Buddhism rooted in Tibet, the tradition of depicting Thangkas might have been prevalent around in the area where
                            Buddhism spread . The tradition of
                            Thangka painting was just outset in Tibet and is not confined only within Tibet and Tibetan community but those who adopted the
                            Tibetan Buddhism learnt this art as well. Worshipping
                            Tara (Doma) goddess results lucrative for the business and as well as the (wrathful diety). Dharmapala protects from the hazardous,
                            calamity, subduing enemies, every accident and fulfilling ones desire in believed with empty heart.
                        </p>
                        <h6 className="text-uppercase mb-2 color-maroon">Prayer Flag</h6>
                        <p className="text-justify">
                            Prayer flags are inscribed with auspicious symbols, invocations, prayers, and mantras. Tibetan
                            Buddhists for centuries have planted these flags outside their homes and places of spiritual practice
                            for the wind to carry the beneficent vibrations across the countryside. Prayer flags are said to bring
                            happiness, long life and prosperity to the flag planter and those in the vicinity.
                        </p>
                        <h6 className="text-uppercase mb-2 color-maroon">Prayer Beads</h6>
                        <p className="text-justify">
                            Buddhist prayer beads are traditional devotional tools of prayer used in various forms of Buddhism.
                            They are similar to other forms of prayer beads and the Rosary used in various world religions; thus this
                            tool has also been known as the Buddhist rosary.
                        </p>
                        <h6 className="text-uppercase mb-2 color-maroon">Prayer Wheels</h6>
                        <p className="text-justify">
                            Tibetan prayer wheels (called Mani wheels by the Tibetans) are devices for spreading spiritual blessings
                            and well being. Rolls of this paper, imprinted with many, many copies of the mantra (prayer) Om Mani
                            Padme Hum, printed in an ancient Indian script or in Tibetan script, are wound around an axle in a
                            protective container, and spun around and around.
                        </p>
                    </div>
                </div>
                <div className={CommonUtils.tabClassName}>
                    <div className={'d-flex main-ul border-bottom'}>
                    <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'thangkas' ? 'active' : '')} onClick={() => this.setActiveTab('thangkas')}>
                        Thangkas
                    </span>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'statues' ? 'active' : '')} onClick={() => this.setActiveTab('statues')}>
                        Statues
                    </span>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'prayerWheels' ? 'active' : '')} onClick={() => this.setActiveTab('prayerWheels')}>
                        Prayer Wheels
                    </span>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'prayerFlags' ? 'active' : '')} onClick={() => this.setActiveTab('prayerFlags')}>
                        Prayer Flags
                    </span>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'prayerBeads' ? 'active' : '')} onClick={() => this.setActiveTab('prayerBeads')}>
                        Prayer Beads
                    </span>
                    </div>
                    <div className={this.state.activeTab === 'thangkas' ? '' : 'd-none'}>
                        <Thangkas setShowModal={this.setShowModal} />
                    </div>
                    <div className={this.state.activeTab === 'statues' ? '' : 'd-none'}>
                        <Statues setShowModal={this.setShowModal} />
                    </div>
                    <div className={this.state.activeTab === 'prayerWheels' ? '' : 'd-none'}>
                        <PrayerWheels setShowModal={this.setShowModal} />
                    </div>
                    <div className={this.state.activeTab === 'prayerFlags' ? '' : 'd-none'}>
                        <PrayerFlags setShowModal={this.setShowModal} />
                    </div>
                    <div className={this.state.activeTab === 'prayerBeads' ? '' : 'd-none'}>
                        <PrayerBeads setShowModal={this.setShowModal} />
                    </div>
                </div>
            </div>
            <MyVerticallyCenteredModal
                show={this.state.showModal}
                img={this.state.img}
                onHide={() => this.setShowModal(false)}
            />
        </div>;
    }
}