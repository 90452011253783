import React, {Component, useState} from "react";
import './sidebar.scss';
import { Link } from 'react-router-dom';
import {connect} from "react-redux";

class Sidebar extends Component {
    render() {
        return <aside className="sidebar lob-bg-light">
            <div className="menu">
                <div className="slimScrollDiv">
                        {this.props.meditationActive && 
                        <ul className="list px-0">
                            <li className="p-0">
                                <Link className={'menu-toggle nav-link px-1 px-md-2 ' + (this.props.activeHeader === 'meditation' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('meditation')} to="/meditation/meditation">
                                    <span className="sidebar-toggle-item">Meditation</span>
                                </Link>
                            </li>
                            <li className="p-0">
                                <Link className={'menu-toggle nav-link px-1 px-md-2 ' + (this.props.activeHeader === 'sound-healing' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('sound-healing')} to="/meditation/sound-healing">
                                    <span className="sidebar-toggle-item">Sound Healing</span>
                                </Link>
                            </li>
                            <li className="p-0">
                                <Link className={'menu-toggle nav-link px-1 px-md-2 ' + (this.props.activeHeader === 'event-calendar' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('event-calendar')} to="/meditation/meditation">
                                    <span className="sidebar-toggle-item">Event Calendar</span>
                                </Link>
                            </li>
                            <li className="p-0">
                                <Link className={'menu-toggle nav-link px-1 px-md-2 ' + (this.props.activeHeader === 'hall-rental' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('hall-rental')} to="/meditation/meditation">
                                    <span className="sidebar-toggle-item">Hall Rental</span>
                                </Link>
                            </li>
                            <li className="p-0">
                                <Link className={'menu-toggle nav-link px-1 px-md-2 ' + (this.props.activeHeader === 'resources' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('resources')} to="/meditation/meditation">
                                    <span className="sidebar-toggle-item">Resources</span>
                                </Link>
                            </li>
                            <li className="p-0">
                                <Link className={'menu-toggle nav-link px-1 px-md-2 ' + (this.props.activeHeader === 'consecration-services' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('consecration-services')} to="/meditation/meditation">
                                    <span className="sidebar-toggle-item">Consecration Services</span>
                                </Link>
                            </li>
                        </ul>
                        }
                        {!this.props.meditationActive && 
                        <ul className="list px-0">
                            <li className="p-0">
                                <Link className={'menu-toggle nav-link px-1 px-md-2 ' + (this.props.activeHeader === 'jewelry' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('jewelry')} to="/jewelry">
                                    <span className="sidebar-toggle-item">Jewelry</span>
                                </Link>
                            </li>
                            <li className="p-0">
                                <Link className={'menu-toggle nav-link px-1 px-md-2 ' + (this.props.activeHeader === 'garments' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('garments')} to="/garments">
                                    <span className="sidebar-toggle-item">Garments</span>
                                </Link>
                            </li>
                            <li className="p-0">
                                <Link className={'menu-toggle nav-link px-1 px-md-2 ' + (this.props.activeHeader === 'religious-items' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('religious-items')} to="/religious-items">
                                    <span className="sidebar-toggle-item">Religious Items</span>
                                </Link>
                            </li>
                            <li className="p-0">
                                <Link className={'menu-toggle nav-link px-1 px-md-2 ' + (this.props.activeHeader === 'handicrafts' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('handicrafts')} to="/handicrafts">
                                    <span className="sidebar-toggle-item">Handicrafts</span>
                                </Link>
                            </li>
                            <li className="p-0">
                                <Link className={'menu-toggle nav-link px-1 px-md-2 ' + (this.props.activeHeader === 'singing-bowls' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('singing-bowls')} to="/singing-bowls">
                                    <span className="sidebar-toggle-item">Singing bowls</span>
                                </Link>
                            </li>
                            <li className="p-0">
                                <Link className={'menu-toggle nav-link px-1 px-md-2 ' + (this.props.activeHeader === 'antiques' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('antiques')} to="/antiques">
                                    <span className="sidebar-toggle-item">Antiques</span>
                                </Link>
                            </li>
                            <li className="p-0">
                                <Link className={'menu-toggle nav-link px-1 px-md-2 ' + (this.props.activeHeader === 'books' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('books')} to="/books">
                                    <span className="sidebar-toggle-item">Books</span>
                                </Link>
                            </li>
                            <li className="p-0">
                                <Link className={'menu-toggle nav-link px-1 px-md-2 ' + (this.props.activeHeader === 'incense-oils' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('incense-oils')} to="/incense-oils">
                                    <span className="sidebar-toggle-item">Incense & Essential Oils</span>
                                </Link>
                            </li>
                            <li className="p-0">
                                <Link className={'menu-toggle nav-link px-1 px-md-2 ' + (this.props.activeHeader === 'tibetan-carpets' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('tibetan-carpets')} to="/tibetan-carpets">
                                    <span className="sidebar-toggle-item">Tibetan Carpets</span>
                                </Link>
                            </li>
                        </ul>
                        }
                    {!this.props.meditationActive &&
                    <div className="text-center extra-links mt-4">
                        {/* <Link className={'cursor-pointer color-white d-inline-block mb-1 '+ (this.props.activeHeader === 'meditation-room' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('meditation-room')} to="/meditation-room"><u>Meditation Room</u></Link><br/>
                        <Link className={'cursor-pointer color-white d-inline-block mb-1 '+ (this.props.activeHeader === 'special-events' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('special-events')} to="/special-events"><u>Event Calendar</u></Link><br/> */}
                        <Link to={'/wholesale'} className={"cursor-pointer color-white d-inline-block mb-1 "+ (this.props.activeHeader === 'wholesale' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('wholesale')}><u>Wholesale</u></Link><br/>
                        <Link to={'/store-info'} className={"cursor-pointer color-white d-inline-block mb-1 "+ (this.props.activeHeader === 'store-info' ? 'active' : '')} onClick={() => this.props.activeHeaderChanged('store-info')}><u>Store Hours, Location</u></Link>
                    </div>
                    }
                </div>
            </div>
        </aside>;
    }
}


const mapStateToProps = (state) => {
    return {
        activeHeader: state.activeHeader,
        activeCarousel: state.activeCarousel,
        meditationActive: state.meditationActive
    }
};
const mapDispatchToProps = (dispach) => {
    return {
        activeHeaderChanged: (value) => dispach({type: 'activeHeader', value: value}),
        activeCarouselChanged: (value) => dispach({type: 'activeCarousel', value: value}),
        meditationActiveChanged: (value) => dispach({type: 'meditationActive', value: value})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);