import React, { Component } from "react";
import './links.scss';

export default class Links extends Component {
    render() {
        return <div className={'links p-1 p-md-4'}>
            <h5 className={'text-uppercase color-grey mb-3'}>Useful Links</h5>
            <ul className={'links-ul'}>
                <li>
                    <a target="_blank" href={'http://www.rmanyc.org/'}>Rubin Museum of Art, NYC</a>
                </li>
                <li>
                    <a target="_blank" href={'https://ny.shambhala.org/'}>Shambhala Meditation Center, NYC</a>
                </li>
                <li>
                    <a target="_blank" href={'http://www.tibethouse.org/'}>Tibet House, New York</a>
                </li>
                <li>
                    <a target="_blank" href={'http://www.tenzingyatsoinstitute.org/'}>Tenzin Gyatso Institute</a>
                </li>
                <li>
                    <a target="_blank" href={'http://viewonbuddhism.org/where_beginner_study_practice_meditation.html'}>Buddhism. Where to Begin?</a>
                </li>
                <li>
                    <a target="_blank" href={'http://www.palyulnyc.org/'}>Phayul New York City</a>
                </li>
                <li>
                    <a target="_blank" href={'http://www.dhamma.org/en/about/vipassana'}>Vipassana Meditation</a>
                </li>
                <li>
                    <a target="_blank" href={'http://usa.rigpa.org/'}>RIGPA</a>
                </li>
                <li>
                    <a target="_blank" href={'http://www.tibetanmuseum.org/'}>The Jacques Marchais Museum of Tibetan Art, Staten Island</a>
                </li>
                <li>
                    <a target="_blank" href={'http://www.ryi.org/'}>RANGJUNG YESHE INSTITUTE (Buddhism Online Course)</a>
                </li>
                <li>
                    <a target="_blank" href={'http://kiva.org/'}>KIVA</a>
                </li>
                <li>
                    <a target="_blank" href={'http://dhenpa.org/'}>Healing Buddha Foundation</a>
                </li>
                <li>
                    <a target="_blank" href={'http://www.artistdreamfamily.com/'}>Helping You Heal Yourself</a>
                </li>
            </ul>
        </div>;
    }
}