import React, { Component } from "react";

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import './specialevents.scss';
import CommonUtils from "../../utils/common";

class SpecialEvents extends Component {
    calendarComponentRef = React.createRef()
    state = {
        calendarWeekends: true,
        calendarEvents: [ // initial event data
            // { title: 'Learn to Meditate', start: new Date() },
            // { title: 'Dharma Gathering', start: new Date('12/27/2019 17:00') },
            // { title: 'Teachings on Buddhism', start: new Date('12/28/2019 10:00') },
        ]
    };
    render() {
        return <div id={'special-events'} className={CommonUtils.mainCompClassName}>
            <h5 className={CommonUtils.pageHeaderClassName}>Event Calendar</h5>
            <FullCalendar
                defaultView="dayGridMonth"
                header={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                }}
                plugins={[ dayGridPlugin, timeGridPlugin ]}
                ref={ this.calendarComponentRef }
                weekends={ this.state.calendarWeekends }
                events={ this.state.calendarEvents }
            />
        </div>;
    }
    // toggleWeekends = () => {
    //     this.setState({ // update a property
    //         calendarWeekends: !this.state.calendarWeekends
    //     })
    // };
    //
    // gotoPast = () => {
    //     let calendarApi = this.calendarComponentRef.current.getApi()
    //     calendarApi.gotoDate('2000-01-01') // call a method on the Calendar object
    // };
}
export default SpecialEvents;