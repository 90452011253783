import React, {Component} from "react";
import one from '../../../assets/img/incenseoils/accessories/1.jpg';
import two from '../../../assets/img/incenseoils/accessories/2.bmp';
import three from '../../../assets/img/incenseoils/accessories/3.jpeg';
import four from '../../../assets/img/incenseoils/accessories/4.jpg';
import five from '../../../assets/img/incenseoils/accessories/5.jpeg';
import six from '../../../assets/img/incenseoils/accessories/6.jpeg';
import seven from '../../../assets/img/incenseoils/accessories/7.jpg';
import eight from '../../../assets/img/incenseoils/accessories/8.jpeg';
import nine from '../../../assets/img/incenseoils/accessories/9.jpeg';
import ten from '../../../assets/img/incenseoils/accessories/10.jpeg';
import eleven from '../../../assets/img/incenseoils/accessories/11.jpg';
import twelve from '../../../assets/img/incenseoils/accessories/12.jpeg';
import CommonUtils from '../../../utils/common';

export default class Accessories extends Component {
    constructor(props) {
        super(props);
    }
    showModal = (img) => {
        this.props.setShowModal(true, img);
    };
    render() {
        return <div className={'row mx-0 statues-items'}>
            <div className={CommonUtils.tabContentClassName}>
                <img src={one} onClick={(e) => {this.showModal(one)}}/>
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={two} onClick={(e) => {this.showModal(two)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={three} onClick={(e) => {this.showModal(three)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={four} onClick={(e) => {this.showModal(four)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={five} onClick={(e) => {this.showModal(five)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={six} onClick={(e) => {this.showModal(six)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={seven} onClick={(e) => {this.showModal(seven)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={eight} onClick={(e) => {this.showModal(eight)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={nine} onClick={(e) => {this.showModal(nine)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={ten} onClick={(e) => {this.showModal(ten)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={eleven} onClick={(e) => {this.showModal(eleven)}} />
            </div>
            <div className={CommonUtils.tabContentClassName}>
                <img src={twelve} onClick={(e) => {this.showModal(twelve)}} />
            </div>
        </div>;
    }
}