import React, { Component } from "react";
import './wholesale.scss';
import CommonUtils from "../../utils/common";

export default class WholeSale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CompanyName: '',
            CompanyAddress: '',
            EmailAddress: '',
            Phone: '',
            TaxID: '',
            ContactPerson: '',
            Position: '',
            CntactPersonPhone: '',
            comments: '',
            formError: false
        };
    }
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value});
    };
    checkForm = (event) => {
        if (!this.state.CompanyName || !this.state.CompanyAddress || 
            !this.state.EmailAddress || !this.state.Phone ||
            !this.state.TaxID || !this.state.ContactPerson ||
            !this.state.Position || !this.state.CntactPersonPhone ||
            !this.state.Position || !this.state.CntactPersonPhone) {
            this.setState({formError: true});
            event.preventDefault();
            return;
        }
    };
    render() {
        return <div className={'wholesale p-md-4 p-1'}>
            <h5 className={CommonUtils.pageHeaderClassName}>OPEN YOUR WHOLESALE ACCOUNT HERE WITH US</h5>
            <p className={'color-maroon'}>
                In order to create wholesale account with us, you must send us the following informations.
            </p>
            <div className={'row mx-0'}>
                <div className={'col-lg-7 wholesale border rounded py-4 col-8 px-2 px-md-4 mb-2'}>
                    <p className={this.state && this.state.formError === false ? 'd-none' : 'text-danger'}>* All fields are required.</p>
                    <form method="post"
                          action="http://us.1.p11.webhosting.luminate.com/forms?login=dawa35@yahoo.com&amp;to=sales@lobny.com&amp;confirm=&amp;error="
                          onSubmit={this.checkForm}>
                        <div className={'form-group d-inline-block w-100'}>
                            <label>Company Name: </label>
                            <input type={'text'} name="CompanyName" className={'form-control'} value={this.state.CompanyName}  onChange={(event) => this.handleUserInput(event)} />
                        </div>
                        <div className={'form-group d-inline-block w-100'}>
                            <label>Company Address: </label>
                            <textarea type={'text'} name="CompanyAddress" rows="5" className={'form-control mb-2 mb-md-0'} value={this.state.CompanyAddress}  onChange={(event) => this.handleUserInput(event)} />
                        </div>
                        <div className={'form-group d-inline-block w-100'}>
                            <label>Email Address: </label>
                            <input type={'email'} name="EmailAddress" className={'form-control'} value={this.state.EmailAddress} onChange={(event) => this.handleUserInput(event)} />
                        </div>
                        <div className={'form-group d-inline-block w-100'}>
                            <label>Phone: </label>
                            <input type={'phone'} name="Phone" className={'form-control'} value={this.state.Phone}  onChange={(event) => this.handleUserInput(event)} />
                        </div>
                        <div className={'form-group d-inline-block w-100'}>
                            <label>Tax ID: </label>
                            <input type={'text'} name="Tax ID" className={'form-control'} value={this.state.TaxID}  onChange={(event) => this.handleUserInput(event)} />
                        </div>
                        <div className={'form-group d-inline-block w-100'}>
                            <label>Contact Person: </label>
                            <input type={'text'} name="ContactPerson" className={'form-control'} value={this.state.ContactPerson}  onChange={(event) => this.handleUserInput(event)} />
                        </div>
                        <div className={'form-group d-inline-block w-100'}>
                            <label>Position: </label>
                            <input type={'text'} name="Position" className={'form-control'} value={this.state.Position}  onChange={(event) => this.handleUserInput(event)} />
                        </div>
                        <div className={'form-group d-inline-block w-100'}>
                            <label>Your phone number: </label>
                            <input type={'phone'} name="CntactPersonPhone" className={'form-control'} value={this.state.CntactPersonPhone}  onChange={(event) => this.handleUserInput(event)} />
                        </div>
                        <div className={'form-group d-inline-block w-100 mb-4'}>
                            <label>Comments: </label>
                            <textarea type={'text'} name="comments" rows="5" className={'form-control'} value={this.state.comments}  onChange={(event) => this.handleUserInput(event)} />
                        </div>
                        <div className={'form-group text-right mb-0 mt-4 mt-md-0'}>
                            <button type={'submit'} className={'btn btn-sm btn-secondary'}>SUBMIT</button>
                        </div>
                    </form>
                </div>
            </div>
            <p className={'color-maroon text-center'}>
                Upon receiving your information, we will first create wholesale account for your
                company, and then email your account number and the wholesale catalog.  <br/>
                Thank you.
            </p>
        </div>;
    }
}