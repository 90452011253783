import React, {Component} from "react";
import one from '../../../assets/img/incenseoils/nepalese/1.jpg';
import CommonUtils from '../../../utils/common';

export default class Accessories extends Component {
    constructor(props) {
        super(props);
    }
    showModal = (img) => {
        this.props.setShowModal(true, img);
    };
    render() {
        return <div className={'row mx-0 accessories-items'}>
            <div className={CommonUtils.tabContentClassName}>
                <img src={one} onClick={(e) => {this.showModal(one)}}/>
            </div>
        </div>;
    }
}