import React, {Component, useState} from "react";
import './jewelry.scss';
import Beads from './beads/beads';
import EarRings from './earRings/earRings';
import FingerRings from './fingerRings/fingerRings';
import Bangles from './bangles/bangles';
import Necklace from './necklace/necklace';
import Pendants from './pendants/pendants';
import CommonUtils from "../../utils/common";
import MyVerticallyCenteredModal from "../../utils/modal";
import {BrowserRouter as Router} from "react-router-dom";

export default class Jewelry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'beads',
            showModal: false,
            img: ''
        }
    }

    setActiveTab = (tab) => {
        this.setState({activeTab: tab})
    };

    setShowModal = (value, img) => {
        this.setState({img: img})
        this.setState({showModal: value})
    };

    render() {
        return <div id={'jewelry'} className={CommonUtils.mainCompClassName}>
            <h5 className={CommonUtils.pageHeaderClassName}>Jewelry</h5>
            <div className={'row mx-0'}>
                <div className={CommonUtils.decsriptionClassName}>
                    <p className="text-justify">
                        Hand made 925 sterling silver jewelry from Nepal and Tibet. <br/>
                        You can find different designs for Ear Rings, Finger Rings, Bangles, Necklace and Pendants here, which were hand made by some of our talented jewelry artists.<br/>
                    </p>
                </div>
                <div className={CommonUtils.tabClassName}>
                    <div className={'d-flex main-ul border-bottom'}>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'beads' ? 'active' : '')}
                              onClick={() => this.setActiveTab('beads')}>
                            Beads
                        </span>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'earRings' ? 'active' : '')}
                              onClick={() => this.setActiveTab('earRings')}>
                            Ear Rings
                        </span>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'fingerRings' ? 'active' : '')}
                              onClick={() => this.setActiveTab('fingerRings')}>
                            Finger Rings
                        </span>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'bangles' ? 'active' : '')}
                              onClick={() => this.setActiveTab('bangles')}>
                            Bangles
                        </span>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'necklace' ? 'active' : '')}
                              onClick={() => this.setActiveTab('necklace')}>
                            Necklace
                        </span>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'pendants' ? 'active' : '')}
                              onClick={() => this.setActiveTab('pendants')}>
                            Pendants
                        </span>
                    </div>
                    <div className={this.state.activeTab === 'beads' ? '' : 'd-none'}>
                        <Beads setShowModal={this.setShowModal}/>
                    </div>
                    <div className={this.state.activeTab === 'earRings' ? '' : 'd-none'}>
                        <EarRings setShowModal={this.setShowModal} />
                    </div>
                    <div className={this.state.activeTab === 'fingerRings' ? '' : 'd-none'}>
                        <FingerRings setShowModal={this.setShowModal} />
                    </div>
                    <div className={this.state.activeTab === 'bangles' ? '' : 'd-none'}>
                        <Bangles setShowModal={this.setShowModal} />
                    </div>
                    <div className={this.state.activeTab === 'necklace' ? '' : 'd-none'}>
                        <Necklace setShowModal={this.setShowModal} />
                    </div>
                    <div className={this.state.activeTab === 'pendants' ? '' : 'd-none'}>
                        <Pendants setShowModal={this.setShowModal} />
                    </div>
                </div>
            </div>

            <MyVerticallyCenteredModal
                show={this.state.showModal}
                img={this.state.img}
                onHide={() => this.setShowModal(false)}
            />
        </div>;
    }
}