import React, { Component } from "react";
import './contactUs.scss';
import CommonUtils from "../../utils/common";

export default class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            comments: '',
            formError: false
        };
    }
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value});
    };
    checkForm = (event) => {
        if (!this.state.email || !this.state.comments) {
            this.setState({formError: true});
            event.preventDefault();
            return;
        }
        // this.setState({
        //     email: '',
        //     comments: '',
        //     formError: false
        // });
    };
    render() {
        return <div className={'contact-us p-md-4 p-1'}>
            <h5 className={CommonUtils.pageHeaderClassName}>Contact Us</h5>
            <p className={'color-maroon'}>
                Use this form to contact us to get more information about our company, products,
                services or wholesale inquiries.
            </p>
            <div className={'row mx-0'}>
                <div className={'col-lg-7 contact-form border rounded py-4 col-7 px-2 px-md-4'}>
                    <p className={this.state && this.state.formError == false ? 'd-none' : 'text-danger'}>* Email and Comments are required.</p>
                    <form method="post"
                          action="http://us.1.p11.webhosting.luminate.com/forms?login=dawa35@yahoo.com&amp;to=sales@lobny.com&amp;confirm=&amp;error="
                          onSubmit={this.checkForm}>
                        <div className={'form-group'}>
                            <label>Name: </label>
                            <input type={'text'} name="name" className={'form-control'} />
                        </div>
                        <div className={'form-group'}>
                            <label>Email Address: </label>
                            <input type={'email'} name="email" className={'form-control'} value={this.state.email} onChange={(event) => this.handleUserInput(event)} />
                        </div>
                        <div className={'form-group'}>
                            <label>Phone # (optional): </label>
                            <input type={'phone'} name="phone" className={'form-control'} />
                        </div>
                        <div className={'form-group d-inline-block w-100 mb-4'}>
                            <label>Comments: </label>
                            <textarea type={'text'} name="comments" rows="5" className={'form-control'} value={this.state.comments}  onChange={(event) => this.handleUserInput(event)} />
                        </div>
                        <div className={'form-group text-right mb-0 mt-4 mt-md-0'}>
                            <button type={'submit'} className={'btn btn-sm btn-secondary'}>SUBMIT</button>
                        </div>
                    </form>
                </div>
                <div className={'col-4'}>
                    <p className={'text-uppercase color-grey mb-1'}>Address</p>
                    <div className="mb-3">
                        Address 1:
                        <br/>
                        <a target="_blank" rel="noopener noreferrer" href={'https://www.google.com/maps/place/128+Macdougal+St,+New+York,+NY+10012/@40.7300842,-74.0024387,17z/data=!3m1!4b1!4m5!3m4!1s0x89c259918474ef39:0xc86243eaffa3b00e!8m2!3d40.7300842!4d-74.00025'}>
                            128 Macdougal St.<br/>
                            NY, NY - 10012, USA
                        </a>
                        <br/>
                        Address 2:
                        <br/>
                        <a target="_blank" rel="noopener noreferrer" href={'https://www.google.com/maps/place/11+St+Marks+Pl,+New+York,+NY+10003/@40.7294474,-73.9911832,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2599b8870e65f:0x4e5f60dcca7872b5!8m2!3d40.7294474!4d-73.9889945'}>
                            11 Saint Mark Place<br/>
                            NY, NY - 10003, USA
                        </a>
                    </div>
                    <p className={'text-uppercase color-grey mb-1'}>Email</p>
                    <div className="mb-3">
                        <a href="mailto:sales@lobny.com">sales@lobny.com</a>
                    </div>
                    <p className={'text-uppercase color-grey mb-1'}>Phone Numbers</p>
                    <div className="mb-3">
                        US: <a href="tel:646-602-6588">(646)-602-6588</a><br/>
                        US: <a href="tel:917-675-6331">(917)-675-6331</a>
                    </div>
                    <p className={'text-uppercase color-grey mb-1'}>Social Profiles: </p>
                    <div className="mb-3">
                        <a title="Instagram" className={'mr-2'} href="https://www.instagram.com/landofbuddha/?hl=en" target="_blank">
                            <i className="reg-fa fa fa-instagram" aria-hidden="true"></i>
                        </a>
                        <a title="Facebook" className={'mr-2'} href="https://www.facebook.com/Land-of-Buddha-100503576677749/" target="_blank">
                            <i className="reg-fa fa fa-facebook" aria-hidden="true"></i>
                        </a>
                        <a title="Twitter" className={'mr-2'} href="https://twitter.com/landofbuddha" target="_blank">
                            <i className="reg-fa fa fa-twitter" aria-hidden="true"></i>
                        </a>
                        <a title="Yelp" className={'mr-2'} href="https://www.yelp.com/biz/land-of-buddha-new-york" target="_blank">
                            <i className="reg-fa fa fa-yelp" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>;
    }
}