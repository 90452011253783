import React, {Component, useState} from "react";
import one from '../../assets/img/books/1.jpg';
import two from '../../assets/img/books/2.jpg';
import three from '../../assets/img/books/3.jpg';
import four from '../../assets/img/books/4.jpg';
import five from '../../assets/img/books/5.jpg';
import six from '../../assets/img/books/6.jpg';
import seven from '../../assets/img/books/7.jpg';
import eight from '../../assets/img/books/8.jpg';
import nine from '../../assets/img/books/9.jpg';
import ten from '../../assets/img/books/10.jpg';
import eleven from '../../assets/img/books/11.jpg';
import twelve from '../../assets/img/books/12.jpg';
import CommonUtils from '../../utils/common';
import MyVerticallyCenteredModal from "../../utils/modal";

export default class Books extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'books',
            setShowModal: false,
            img: ''
        }
    }

    setActiveTab = (tab) => {
        this.setState({activeTab: tab})
    };

    setShowModal = (value, img) => {
        this.setState({img: img});
        this.setState({setShowModal: value})
    };
    render() {
        return <div id={'incense-oils'} className={CommonUtils.mainCompClassName}>
                <h5 className={CommonUtils.pageHeaderClassName}>Books</h5>
                <div className={'row mx-0'}>
                    <div className={CommonUtils.decsriptionClassName}>
                        <p className="text-justify">
                            We carry a variety of books on subject matters including Buddhism, Yoga, Spirituality, Meditation and Singing Bowls.
                        </p>
                    </div>
                    <div className={CommonUtils.tabClassName}>
                        <div className={this.state.activeTab === 'books' ? '' : 'd-none'}>
                            <div className={'row books-items mx-0'}>
                                <div className={CommonUtils.tabContentClassName}>
                                    <img src={one} onClick={(e) => {this.setShowModal(true, one)}}/>
                                </div>
                                <div className={CommonUtils.tabContentClassName}>
                                    <img src={two} onClick={(e) => {this.setShowModal(true, two)}} />
                                </div>
                                <div className={CommonUtils.tabContentClassName}>
                                    <img src={three} onClick={(e) => {this.setShowModal(true, three)}} />
                                </div>
                                <div className={CommonUtils.tabContentClassName}>
                                    <img src={four} onClick={(e) => {this.setShowModal(true, four)}} />
                                </div>
                                <div className={CommonUtils.tabContentClassName}>
                                    <img src={five} onClick={(e) => {this.setShowModal(true, five)}} />
                                </div>
                                <div className={CommonUtils.tabContentClassName}>
                                    <img src={six} onClick={(e) => {this.setShowModal(true, six)}} />
                                </div>
                                <div className={CommonUtils.tabContentClassName}>
                                    <img src={seven} onClick={(e) => {this.setShowModal(true, seven)}} />
                                </div>
                                <div className={CommonUtils.tabContentClassName}>
                                    <img src={eight} onClick={(e) => {this.setShowModal(true, eight)}} />
                                </div>
                                <div className={CommonUtils.tabContentClassName}>
                                    <img src={nine} onClick={(e) => {this.setShowModal(true, nine)}} />
                                </div>
                                <div className={CommonUtils.tabContentClassName}>
                                    <img src={ten} onClick={(e) => {this.setShowModal(true, ten)}} />
                                </div>
                                <div className={CommonUtils.tabContentClassName}>
                                    <img src={eleven} onClick={(e) => {this.setShowModal(true, eleven)}} />
                                </div>
                                <div className={CommonUtils.tabContentClassName}>
                                    <img src={twelve} onClick={(e) => {this.setShowModal(true, twelve)}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MyVerticallyCenteredModal
                    show={this.state.setShowModal}
                    img={this.state.img}
                    onHide={() => this.setShowModal(false, '')}
                />
            </div>;
    }
}