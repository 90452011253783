import React, {Component} from "react";
import CommonUtils from '../../../utils/common';
import MyVerticallyCenteredModal from "../../../utils/modal";
import "./meditation.scss";

export default class Meditation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'books',
            setShowModal: false,
            img: ''
        }
    }

    setActiveTab = (tab) => {
        this.setState({activeTab: tab})
    };

    setShowModal = (value, img) => {
        this.setState({img: img});
        this.setState({setShowModal: value})
    };

    render() {
        return <div id={'meditation'} className={CommonUtils.mainCompClassName}>
            <h5 className={CommonUtils.pageHeaderClassName}>Meditation</h5>
            <div className={'row mx-0'}>
                <div className={CommonUtils.decsriptionClassName}>
                    <p className="text-justify">
                        The Meditation Room is located inside the new “Land of Buddha” store location on 11 Saint Marks
                        Place, in the East Village, Manhattan, New York City.
                    </p>
                    <video className="pull-left mr-2" width="200px" height="auto" autoPlay={true} loop={true} muted={true}>
                        <source src="https://landofbuddha.s3.us-east-2.amazonaws.com/Sound+Bath+Sessions/LandofBuddha_MeditationRoom_GroupSoundBathSetUp.MOV" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <p className="text-justify">
                        It was constructed by the owner to be a sacred healing space dedicated to Meditation and Sound
                        Healing practices, with a focus on educating and supporting mindfulness and meditation teachers and
                        sound healing practitioners. It is the owner’s mission to make meditation, sound healing and spirituality
                        more accessible to serve the public by offering the space to help people reach levels of peace and calm
                        through the physical practices of meditation and through sound baths, in hopes of contributing to the
                        development of a thoughtful, kindhearted, and contemplative society.
                    </p>
                    <p className="text-justify">
                        Looking like a museum or an official Tibetan/Buddhist temple, the Meditation Room is decorated with
                        numerous antiques and Buddhist religious items, beautifully hand painted Buddhist art works,
                        handmade masks and other crafts, traditional Tibetan rugs, and consecrated statues. 
                    </p>
                    <p className="text-justify">
                        Meditation and Sound Healing Classes are offered every week. 
                    </p>
                    <p className="text-justify">
                        The Meditation classes are offered both virtually and in-house every Monday evening at 6pm.
                        Depending on the topic covered, each meditation class will consist of a combination of wisdom training
                        and guided meditation practice. Guided Meditation Practice is roughly 20 minutes. The wisdom training
                        consists of a lecture covering a weekly topic. If attending on a weekly basis, this class will give you the
                        spiritual training you need to develop a profound meditation practice. Taught by Zen Master Seiso
                        Sensei, member of the Soto Zen Buddhist Association & the American Zen Teacher’s Association, the
                        weekly meditation class is $15 to attend in-person class, or free to attend virtually online through Zoom.
                    </p>
                    <p className="text-justify">
                        Sound Healing Classes or Small Group Sound Bath Sessions are offered every Tuesday and Wednesday
                        evenings inside the Meditation Room at the Land of Buddha. Classes consist of both Sound and
                        Vibrational Healing for a more immersive sound healing experience using musical instruments like
                        antique Tibetan and Himalayan Singing Bowls, Gongs, Crystal Singing Bowls, Japanese Rin Gong Bowls,
                        Tingshas, Koshi Chimes. The Sound Bath sessions and Sound Healing classes are performed by certified
                        practitioner Mel Rio, owner/founder of the Singing Bowl Sound Bath Studio LLC. and are offered in
                        person only. $65 to attend the in-person class. Private sessions are also available. Reservations are
                        required to book a private session. 
                    </p>
                </div>
                <div className={CommonUtils.tabClassName}>
                    {/* <div className={'row mx-0'}>
                        <video className="pull-right ml-md-3 ml-1" width="40%" height="auto" autoPlay={true} loop={true} muted={true}>
                            <source src="https://landofbuddha.s3.us-east-2.amazonaws.com/Sound+Bath+Sessions/IMG_9367.MOV" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <video className="pull-right ml-md-3 ml-1" width="40%" height="auto" autoPlay={true} loop={true} muted={true}>
                            <source src="https://landofbuddha.s3.us-east-2.amazonaws.com/Sound+Bath+Sessions/IMG_9486.mov" type="video/mp4" />
                            Your browser does not support the video tag."
                        </video>
                        <video className="pull-right ml-md-3 ml-1" width="80%" height="300px" autoPlay={true} loop={true} muted={true}>
                            <source src="https://landofbuddha.s3.us-east-2.amazonaws.com/Sound+Bath+Sessions/LandofBuddha_MeditationRoom_GroupSoundBathSetUp.MOV" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div> */}
                    <div className={'row mx-0 justify-content-sp-evenly'}>
                        <div className={CommonUtils.tabContentClassNameMeditation}>
                            <img src="https://landofbuddha.s3.us-east-2.amazonaws.com/400x400/LandOfBuddha_MeditationRoom_MeditationSession.jpg"
                                 alt="img"
                                 onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/400x400/LandOfBuddha_MeditationRoom_MeditationSession.jpg")}}/>
                        </div>
                        <div className={CommonUtils.tabContentClassNameMeditation}>
                            <img src="https://landofbuddha.s3.us-east-2.amazonaws.com/400x400/LandOfBuddha_MeditationRoom_MeditationSession1.jpg"
                                alt="img" 
                                onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/400x400/LandOfBuddha_MeditationRoom_MeditationSession1.jpg")}}/>
                        </div>
                        <div className={CommonUtils.tabContentClassNameMeditation}>
                            <img src="https://landofbuddha.s3.us-east-2.amazonaws.com/400x400/LandOfBuddha_MeditationRoom_MeditationSession2.jpg" 
                                alt="img" 
                                onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/400x400/LandOfBuddha_MeditationRoom_MeditationSession2.jpg")}}/>
                        </div>
                        <div className={CommonUtils.tabContentClassNameMeditation}>
                            <img src="https://landofbuddha.s3.us-east-2.amazonaws.com/400x400/LandOfBuddha_MeditationRoom_MeditationSession3.jpg" 
                                alt="img" 
                                onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/400x400/LandOfBuddha_MeditationRoom_MeditationSession3.jpg")}}/>
                        </div>
                        <div className={CommonUtils.tabContentClassNameMeditation}>
                            <img src="https://landofbuddha.s3.us-east-2.amazonaws.com/400x400/LandOfBuddha_MeditationRoom_MeditationSetUp.jpg" 
                                alt="img" 
                                onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/400x400/LandOfBuddha_MeditationRoom_MeditationSetUp.jpg")}}/>
                        </div>
                        <div className={CommonUtils.tabContentClassNameMeditation}>
                            <img src="https://landofbuddha.s3.us-east-2.amazonaws.com/Meditation+Room+Images/seven.jpg" alt="img" onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/Meditation+Room+Images/seven.jpg")}}/>
                        </div>
                        <div className={CommonUtils.tabContentClassNameMeditation}>
                            <img src="https://landofbuddha.s3.us-east-2.amazonaws.com/400x400/LandOfBuddha_MeditationRoom_MeditationSetUpFront.jpg" 
                                alt="img" 
                                onClick={(e) => {this.setShowModal(true, "https://landofbuddha.s3.us-east-2.amazonaws.com/400x400/LandOfBuddha_MeditationRoom_MeditationSetUpFront.jpg")}}/>
                        </div>
                    </div>
                </div>
            </div>
            <MyVerticallyCenteredModal
                    show={this.state.setShowModal}
                    img={this.state.img}
                    onHide={() => this.setShowModal(false, '')}
                />
        </div>;
    }
}