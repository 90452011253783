import React, {Component, useState} from "react";
import SBAccessories from './accessories/accessories';
import Crystal from './crystal/crystal';
import Copper from './copper/copper';
import TraditionalTibetan from './traditionaltibetan/traditionaltibetan';
import './singingbowls.scss';
import CommonUtils from "../../utils/common";
import MyVerticallyCenteredModal from "../../utils/modal";

export default class SingingBowls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'traditionalTibetan',
            showModal: false,
            img: ''
        }
    }

    setActiveTab = (tab) => {
        this.setState({activeTab: tab})
    };

    setShowModal = (value, img) => {
        this.setState({img: img})
        this.setState({showModal: value})
    };

    render() {
        return <div id={'singing-bowls'} className={CommonUtils.mainCompClassName}>
            <h5 className={CommonUtils.pageHeaderClassName}>Singing bowls</h5>
            <div className={'row mx-0'}>
                <div className={CommonUtils.decsriptionClassName}>
                    <h6 className="text-uppercase mb-2 color-maroon">WHAT IS SINGING BOWL ?</h6>
                    <p className="text-justify">
                        Singing Bowls have been widely used since the ancient times in Nepal and Tibet
                        mostly by the Buddhist monks for meditation. Singing bowls, when tapped, hit or
                        rubbed with a wooden mallet or dowel, produces soothing vibrations and sounds
                        that will almost instantly calm our nervous system and put us into a meditative or
                        neutral state of mind which itself conducive to meditation. All of which combine
                        will relieve all forms of stress.
                        Now a days singing bowls are used for medical cures and treatments through
                        applied sound therapy and massage which serve as a basis for holistic healing.
                    </p>
                    <h6 className="text-uppercase mb-2 color-maroon">PLAY SINGING BOWL ?</h6>
                    <ul className="text-justify pl-3 pl-md-4">
                        <li>
                            Hold the bowl in the palm of either hand without grabbing the sides of the bowl
                            as that is where the vibration comes from.
                        </li>
                        <li>
                            With an even pressure, rub the striker outside rim in a circular motion without
                            going too fast or too slow. The friction of the striker against the outer rim
                            produces vibrations which result in sound. Sometimes it is easier to strike the
                            bowl first to get the vibrations going.
                        </li>
                        <li>
                            When the sound reaches its best, comfortably separate the striker from the
                            bowl.
                        </li>
                        <li>
                            Another way is just to strike the bowl with a gong striker that is
                            shown here in the picture.
                        </li>
                    </ul>
                </div>
                <div className={CommonUtils.tabClassName}>
                    <div className={'d-flex main-ul border-bottom'}>
                    <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'traditionalTibetan' ? 'active' : '')} onClick={() => this.setActiveTab('traditionalTibetan')}>
                        Traditional Tibetan
                    </span>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'copper' ? 'active' : '')} onClick={() => this.setActiveTab('copper')}>
                        Copper
                    </span>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'crystal' ? 'active' : '')} onClick={() => this.setActiveTab('crystal')}>
                        Crystal
                    </span>
                        <span className={"cursor-pointer sub-nav-item py-1 py-md-2 border-right " + (this.state.activeTab === 'accessories' ? 'active' : '')} onClick={() => this.setActiveTab('accessories')}>
                        Accessories
                    </span>
                    </div>
                    <div className={this.state.activeTab === 'traditionalTibetan' ? '' : 'd-none'}>
                        <TraditionalTibetan setShowModal={this.setShowModal} />
                    </div>
                    <div className={this.state.activeTab === 'copper' ? '' : 'd-none'}>
                        <Copper setShowModal={this.setShowModal} />
                    </div>
                    <div className={this.state.activeTab === 'crystal' ? '' : 'd-none'}>
                        <Crystal setShowModal={this.setShowModal} />
                    </div>
                    <div className={this.state.activeTab === 'accessories' ? '' : 'd-none'}>
                        <SBAccessories setShowModal={this.setShowModal} />
                    </div>
                </div>
            </div>
            <MyVerticallyCenteredModal
                show={this.state.showModal}
                img={this.state.img}
                onHide={() => this.setShowModal(false)}
            />
        </div>;
    }
}