import React, {Component, useState} from "react";
import CommonUtils from '../../utils/common';

export default class MeditaionRoom extends Component {
    render() {
        return <div id={'incense-oils'} className={CommonUtils.mainCompClassName}>
        <h5 className={CommonUtils.pageHeaderClassName}>Meditation Room</h5>
        <div className={'text-center'}>
            <h6 className="text-uppercase mb-2 color-maroon">Under Construction ...</h6>
            <br/>
            <i class="fa fa-wrench mr-3" aria-hidden="true" style={{fontSize: '150px', color: '#CCC'}}></i>
            <i class="fa fa-cogs" aria-hidden="true" style={{fontSize: '150px', color: '#CCC'}}></i>
        </div>
    </div>;
    }
}